import { Stack, Typography, Button } from '@mui/material';
import { EmailAutoComplete } from '../../../../../components/ui/EmailAutoComplete';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { BackButton } from '../../../../../components/BackButton/style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { yupResolver } from '@hookform/resolvers/yup';
import { loanDataSchema } from '../../../../../validation/loan.schema';
import { checkEmailRequest } from '../../../../../axios/api';
import { FormWrapper } from '../../../../../components/ui/FormWrapper/form.style';
import { INCORRECT_EMAIL } from '../../../../../validation/messages';
import { TextFieldRHF } from '../../../../../components';
import { PhoneNumberInput } from '../../../../../components/ui/PhoneNumberInput/PhoneNumberInput';
import { ButtonWrapper } from '../../LoanForm/style';
import { useLoanContext } from '../../../context/context';
import useScrollToTopOnPageChange from '../../../../../hooks/useScrollToTopOnPageChange';

interface IProps {
  onHandleStep: (stepAction: number) => void;
}
interface IFormData {
  email: string;
  phone: string;
  cnp: string;
}
export const StepThree = ({ onHandleStep }: IProps) => {
  useScrollToTopOnPageChange();
  const { email, phone, cnp, updateLoanData } = useLoanContext();
  const methods = useForm<IFormData>({
    resolver: yupResolver(loanDataSchema),
    mode: 'onChange',
    defaultValues: {
      email: email,
      phone: phone,
      cnp: cnp,
    },
    reValidateMode: 'onBlur',
  });

  const { setError, watch } = methods;
  const values = watch();
  const onSubmit: SubmitHandler<IFormData> = (data: IFormData) => {
    updateLoanData({ email: data.email, phone: data.phone, cnp: data.cnp });
    checkEmailRequest({ email: data.email }).then(({ data }) => {
      if (!data.data.email_valid) {
        setError('email', { type: 'server', message: INCORRECT_EMAIL });
      } else {
        onHandleStep(1);
      }
    });
  };
  return (
    <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Stack flex={1} justifyContent='center'>
          <Typography variant='h3' sx={{ margin: '0 -16px 22px' }} textAlign='center'>
            Your data?
          </Typography>
          <EmailAutoComplete name='email' placeholder='willie.jennings@example.com' />
          <PhoneNumberInput
            name='phone'
            value={values.phone}
            mask='+40 (99) 999-9999'
            placeholder='+40 XX XXX XXXX'
          />
          <TextFieldRHF name='cnp' type='tel' placeholder='Cod numeric personal' />
        </Stack>
        <ButtonWrapper>
          <BackButton
            variant='contained'
            startIcon={<ArrowBackIcon />}
            onClick={() => onHandleStep(-1)}
          >
            Back
          </BackButton>

          <Button variant='contained' endIcon={<ArrowForwardIcon />} type='submit'>
            Next
          </Button>
        </ButtonWrapper>
      </FormProvider>
    </FormWrapper>
  );
};
