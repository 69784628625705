import { Stack, styled, Typography } from '@mui/material';

export const WrapperStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  maxWidth: '475px',
  maxHeight: '444px',
  background: theme.palette.white,
  padding: '37px 40px 44px',
  alignItems: 'center',
  borderRadius: '24px',
  ['@media(max-width: 500px)']: {
    padding: '20px 20px 24px',
  },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  maxWidth: '360px',
  textAlign: 'center',
  marginTop: '28px',
  color: theme.palette.black[60],
  ['@media(max-width: 500px)']: {
    marginTop: '16px',
  },
}));
