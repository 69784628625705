import { createTheme } from '@mui/material/styles';

const Palette = createTheme({
  palette: {
    bgColor: 'rgba(234, 255, 250, 1)',
    black: {
      5: '#F1F0F1',
      10: '#EAE8EA',
      20: '#D9D8DA',
      30: '#C3C4C8',
      35: '#D2D2D2',
      40: '#999EA4',
      60: '#6F7781',
      70: '#727980',
      80: '#45515E',
      90: '#414549',
      100: '#1B2A3A',
    },
    turquoise: '#75DCC3',
    tableBorder: '#DCEEEB',
    frost: '#EEFBF8',
    pearlAqua: '#20B2AA',
    goldenrod: { 80: '#F6EED7', 100: '#DBBC5E' },
    lavender: {
      60: '#D48ADB',
      80: '#C768D1',
      100: '#8C2E96',
    },
    white: '#ffffff',
    red: '#FF3B30',
  },
});
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    bgColor?: string;
    black?: {
      5?: string;
      10?: string;
      20?: string;
      30?: string;
      35?: string;
      40?: string;
      60?: string;
      70?: string;
      80?: string;
      90?: string;
      100?: string;
    };
    turquoise?: string;
    tableBorder?: string;
    frost?: string;
    pearlAqua?: string;
    goldenrod?: {
      80?: string;
      100?: string;
    };
    lavender?: {
      60?: string;
      80?: string;
      100?: string;
    };
    white?: string;
    red?: string;
  }
  interface Palette {
    bgColor: string;
    black: {
      5: string;
      10: string;
      20: string;
      30: string;
      35: string;
      40: string;
      60: string;
      70: string;
      80: string;
      90: string;
      100: string;
    };
    turquoise: string;
    tableBorder: string;
    frost: string;
    pearlAqua: string;
    goldenrod: {
      80: string;
      100: string;
    };
    lavender: {
      60: string;
      80: string;
      100: string;
    };
    white: string;
    red: string;
  }
}

export default Palette;
