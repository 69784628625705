import { Stack, Typography, styled } from '@mui/material';

export const WrapperStack = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '64px',
  ['@media(max-width: 1000px)']: {
    marginTop: '20px',
    gap: 0,
    flexDirection: 'column',
  },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  fontSize: '16px',
  color: theme.palette.black[60],
  ['@media(max-width: 1000px)']: {
    fontSize: '14px',
    marginTop: '15px',
  },
}));

export const TitleStyle = styled(Typography)(() => ({
  marginTop: '40px',
  ['@media(max-width: 1000px)']: {
    marginTop: '30px',
  },
}));
