import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px',
  borderRadius: '50px',
  marginTop: '16px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.goldenrod[80],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.goldenrod[100],
  },
}));
