import { TextStyle, TextBold, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';
import { Link } from '@mui/material';

export const InfoSecond = () => {
  return (
    <WrapperStack>
      <TextStyle>
        4.3. There are several ways and circumstances in which We may obtain Personal Data from You,
        including but not restricted to, access to the Finlead LTD, when You go to Our Website, use
        contact Us form, fill User`s Form, contact Us by way of email address{' '}
        <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link> etc. We shall be
        entitled to combine Personal Data collected in the course of Your interaction with different
        sections of the Services with any other relevant available information.
      </TextStyle>
      <TitleStyle variant='h5'>5. SHARING YOUR PERSONAL DATA</TitleStyle>
      <TextStyle>
        9.1.1.By completing and submitting a User Form, You agree to the disclosure of Your Personal
        Data to the following parties:
      </TextStyle>
      <TextStyle>
        9.1.1.1. Finlead Ltd: (i) to contact You, in particular to send information about Services,
        Creditor Information and products that may interest You; (ii) to facilitate the filling of
        the form or to personalize or improve the interaction with the website or its mobile
        applications; or (iii) in accordance with its Privacy Policy.
      </TextStyle>
      <TextStyle>
        9.1.2. Where permitted by privacy and data protection law, We may need to access Your
        account and/or disclose information about You (including electronic identifiers such as IP
        addresses):
      </TextStyle>
      <TextStyle>9.1.3.1. if permitted or required by law.</TextStyle>
      <TextStyle>
        9.1.3.2. if required by any court or other regulatory authority, regulatory authority,
        governmental or law enforcement authority.
      </TextStyle>
      <TextStyle>
        9.1.3.3. where necessary in connection with litigation or imminent litigation; and/or
      </TextStyle>
      <TextStyle>
        9.1.3.4. combined with the sale or potential sale of all or part of Our business.
      </TextStyle>
      <TextStyle>
        9.1.3. Some Creditors in Our group may use Your Personal Data to assess Your situation,
        including information about third parties identified in User Form and Report, and to verify
        the accuracy of any information provided before making an offer to You. Some Creditors may
        conduct audits at fraud prevention agencies and credit reference agencies. You can check
        both personal and public data. Some Creditors may check data about You that they already
        have, such as data from existing financial products, account information, or previous
        transaction information, to determine the amount of Your loan. If vendors make such
        searches, Your Report will include a search record.
      </TextStyle>
      <TitleStyle variant='h5'>6. LEGAL GROUNDS FOR DATA PROCESSING</TitleStyle>
      <TextStyle>
        6.1. By using Company`s Services the User gives its consent to receive the information
        required for Company`s Services in accordance with legislation requirements.
      </TextStyle>
      <TextStyle>
        6.2. If You are a resident of the European Economic Area (EEA) You have the right to
        privacy, and all processing of Personal Data is done in accordance with the
        <Link href='http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG'>
          {' '}
          Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
          the protection of natural persons with regard to the processing of personal data and on
          the free movement of such data, and repealing Directive 95/46/EC («GDPR»).
        </Link>
      </TextStyle>
      <TextStyle>
        6.3. We may share Your Personal Data with Creditors and other contractors exclusively for
        the provision of Services. This includes various tasks such as the analysis of Personal
        Data, email delivery, hosting services, customer service, and, if applicable, marketing
        assistance.
      </TextStyle>
      <TextStyle>
        6.4. Legitimate interest derives from
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/119472#:~:text=Creditorul%20realizeaz%C4%83%20evaluarea,%C8%99i%20complet%C4%83rile%20ulterioare.'>
          {' '}
          EMERGENCY DECREE No.50 of June 9
        </Link>
        , 2010 under which banks, credit institutions, non-bank financial institutions,
        institutions, issuing electronic money, payment institutions have the right to receive this
        information in order to assess credit risk, analysis of the state of credit or achievement
        of legitimate interest by the User or a third party, to which the data are disclosed,
        provided that this interest does not violate the interests of the rights and fundamental
        freedoms of the person concerned. Legitimate interest also arises from the need to carry out
        responsible credit activities by complying with data protection laws, credit risk assessment
        and credit risk management. These Services help interested parties assess their credit
        suitability by agreeing to the processing of Personal Data provided by the Company and
        requesting them during the provision of the Services, as well as receive offers from
        potential Lenders. The Services help the Lenders assess their credit risks to the relevant
        User and make a business decision based on this information, which is an important part of
        the process in accordance with applicable credit legislation.
      </TextStyle>
      <TextStyle>
        6.5. The Company does not form the User’s credit history and is not responsible to the User
        for the decision of the Creditor based on the assessment of the User’s creditworthiness.
      </TextStyle>
      <TextStyle>
        6.6. Using the Services of the Company, the User agrees to obtain the necessary information
        for the Services of the Company from the relevant database in accordance with the{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/32733#:~:text=c%C3%A2nd%20prelucrarea%20este%20necesar%C4%83%20%C3%AEn%20vederea%20realiz%C4%83rii%20unui%20interes%20legitim%20al%20operatorului%20sau%20al%20ter%C8%9Bului%20c%C4%83ruia%20%C3%AEi%20sunt%20dezv%C4%83luite%20datele%2C%20cu%20condi%C8%9Bia%20ca%2'>
          {' '}
          Law No.677/2001
        </Link>{' '}
        on the Protection of Individuals in Relation to the Processing of Personal Data{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/32733#:~:text=c%C3%A2nd%20prelucrarea%20este%20necesar%C4%83%20%C3%AEn%20vederea%20realiz%C4%83rii%20unui%20interes%20legitim%20al%20operatorului%20sau%20al%20ter%C8%9Bului%20c%C4%83ruia%20%C3%AEi%20sunt%20dezv%C4%83luite%20datele%2C%20cu%20condi%C8%9Bia%20ca%2'>
          {' '}
          Law No.677/2001
        </Link>{' '}
        — to achieve the legitimate interest of the User or a third party, to which the data are
        disclosed, and confirms that this interest does not harm the interests or fundamental rights
        and freedoms of the relevant User in accordance with{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/119472#:~:text=Creditorul%20realizeaz%C4%83%20evaluarea,%C8%99i%20complet%C4%83rile%20ulterioare.'>
          {' '}
          EMERGENCY DECREE No. 50 of June 9, 2010
        </Link>
        .
      </TextStyle>
      <TextStyle>
        6.7. Processing of Personal Data is carried out with a high level of security in accordance
        with the GDPR and with a high level of confidentiality — the information is not public and
        is available to the Company, the User and the potential Lender only on condition that the
        User makes a request and gives his consent.
      </TextStyle>
      <TextStyle>
        6.8. All aspects of Your Personal Data are governed by Our Privacy Policy, and You agree to
        the collection of such data through the Website; therefore, We recommend, and You should
        make sure that You carefully read the provisions of the Privacy Policy.
      </TextStyle>
      <TitleStyle variant='h5'>7. YOUR CONSENT</TitleStyle>
      <TextStyle>
        7.1. By agreeing to this Privacy Policy, You confirm that You have attained the age of
        majority or the legal age in Your jurisdiction (typically 18 or older), assume full
        responsibility for all Your actions, and comprehend the terms outlined in this Privacy
        Policy. Upon submitting Your Personal Data to Us through the User Form, Website, or any
        other means, You unequivocally and unconditionally Consent and agree that We have the right,
        in accordance with this Privacy Policy:
      </TextStyle>
      <TextStyle>
        7.2. to process Your Personal Data in any manner, encompassing collection, storage,
        utilization, disclosure, sharing, and transfer (including cross—border) of the provided
        Personal Data, as well as Personal Data gathered from Your utilization of the Services (such
        as Personal Data collected automatically and/or from other sources); and
      </TextStyle>
      <TextStyle>
        7.3. to utilize Cookies and similar technologies for the collection of Your Personal Data.
      </TextStyle>
      <TextStyle>
        7.4. We assume that all Users have carefully read this document and agree to its content. If
        one does not agree with this Privacy Policy, they should refrain from using the Services.
      </TextStyle>
      <TitleStyle variant='h5'>8. YOUR PRIVACY RIGHTS</TitleStyle>
      <TextStyle>
        8.1. We admit and respect the right to privacy and understand the importance of protecting
        Personal Data. Pursuant to the law applicable to You and subject to certain conditions, You
        have a number of rights concerning the Personal Data that We process.
      </TextStyle>
      <TextStyle>8.2. The rights which Users have upon the GDPR:</TextStyle>
      <TextStyle>
        8.2.1.Right to information: We provide transparent and clear information about the methods
        of collecting, controlling and processing Personal Data.
      </TextStyle>
      <TextStyle>
        8.2.2. Right to access. You may contact Us to get confirmation as to whether or not We are
        processing Your Personal Data.
      </TextStyle>
      <TextStyle>
        8.2.3. Right to withdraw Consent. In case Our processing is based on the Consent granted,
        You may withdraw the Consent at any time by contacting us. You can withdraw Your Consent at
        any time by replying to the email with Your withdrawal and Your Personal Data will be
        deleted in <TextBold>48 hours.</TextBold>
      </TextStyle>
      <TextStyle>
        8.2.4. Right to object. In case Our processing is based on Our legitimate interest to run,
        maintain and develop Our business, You have the right to object at any time to Our
        processing. We shall then no longer process Your Personal Data unless for the provision of
        Our Services or if We demonstrate other compelling legitimate grounds for Our processing
        that override Your interests, rights, and freedoms or for legal claims.
      </TextStyle>
      <TextStyle>
        8.2.5. Right to restriction of the processing. You have the right to obtain restriction of
        processing of Your Personal Data, as foreseen by applicable data protection law, e.g. to
        allow Our verification of the accuracy of Personal Data after Your contesting of accuracy or
        to prevent Us from erasing Personal Data when Personal Data is no longer necessary for the
        purposes but still required for Your legal claims or when Our processing is unlawful.
        Restriction of processing may lead to fewer possibilities or the impossibility to use Our
        Services.
      </TextStyle>
      <TextStyle>
        8.3. How to use these rights. To exercise any of the above—mentioned rights, You should
        primarily use the functions offered by Our Services. If such functions are not sufficient
        for exercising such rights, You shall send Us a letter or email to the address set out
        below, including (but not limited to) the following information: full name, phone number,
        email address.
      </TextStyle>
      <TitleStyle variant='h5'>9. STORING OF PERSONAL DATA AND DELETION</TitleStyle>
      <TextStyle>
        9.1. <TextBold>EU Residents.</TextBold> We keep Personal Data for as long as it’s required
        to provide Our Services. For the sole purpose of carrying out a communication or as strictly
        necessary for Us in order for Us to perform the Service You requested, this Section shall
        not prohibit any technical storage or access to the Personal Data.
      </TextStyle>
      <TextStyle>
        9.2. As explained in the GDPR statement, We strive to anonymize the data when possible. If
        You decide to exercise Your right to erasure We will also inform Our Service providers to
        delete all Your data.
      </TextStyle>
      <TextStyle>
        {/*тут*/}
        9.3. <TextBold>Non-EU Persons Consent to Personal Data processing.</TextBold> If You are not
        an EU Person We receive Your consent and process Your Personal Data, as it is prescribed by
        the laws of Your country.
      </TextStyle>
      <TitleStyle variant='h5'>10. CHILDREN</TitleStyle>
      <TextStyle>
        10.1. We never sell any Services to children. If You are under 18, You can use our website
        only under the supervision of a person performing parental duties. We never intentionally
        collect Personal Data from children under 18 without the consent of a person performing
        parental duties.
      </TextStyle>
      <TitleStyle variant='h5'>11. TRANSFER OF DATA</TitleStyle>
      <TextStyle>
        11.1. Your information, including Personal Data, may be transferred to and stored on
        computers located outside of Your state, province, country, or other governmental
        jurisdiction. In such locations, data protection laws may differ from those in Your
        jurisdiction.
      </TextStyle>
      <TextStyle>
        11.2. Please be aware that We transfer and handle data, including Personal Data, to the
        European Union if You choose to send it to Us regardless of whether you are in the EU or
        not.
      </TextStyle>
      <TextStyle>
        11.3. Your agreement to the transfer is shown by Your acceptance of this Privacy Policy,
        Website usage continuation, and Your submission of such information.
      </TextStyle>
      <TextStyle>
        11.4. Finlead LTD is committed to taking all reasonable steps to ensure the secure treatment
        of Your data in accordance with this Privacy Policy. No transfer of Your Personal Data will
        occur to an organization or country unless adequate controls are in place, including the
        security of Your data and other Personal Data.
      </TextStyle>
      <TitleStyle variant='h5'>12. DO NOT TRACK SIGNALS</TitleStyle>
      <TextStyle>
        12.1. Do Not Track is a preference You can set in Your web browser to let the websites You
        visit know that You do not want them collecting data about You. At this time, this Website
        does not respond to Do Not Track or similar signals.
      </TextStyle>
      <TitleStyle variant='h5'>13. INFORMATION SECURITY</TitleStyle>
      <TextStyle>
        13.1. We are concerned about protecting Personal Data. We safeguard the information that is
        supplied to us both during transmission, and after We receive it by adhering to widely
        recognized industry standards. We keep administrative, physical, and technical security
        measures in place to give Your Personal Data an adequate level of protection. Furthermore,
        We also ensure that Your Personal Data remains safe from loss, unauthorized access,
        alteration, falsification, destruction, and disclosure when We or Our contractors process
        it. Appropriate administrative, technical, and physical measures are used to accomplish
        this.
      </TextStyle>
      <TextStyle>
        13.2. Your Personal Data is stored in{' '}
        <Link href='https://www.hetzner.com/'> https://www.hetzner.com/</Link> hosting, but We
        reserve the right to change hosting as needed.
      </TextStyle>
      <TextStyle>
        13.3. There isn’t a completely safe way to store or transmit data electronically over the
        Internet. As a result, We are unable to guarantee complete security.
      </TextStyle>
      <TextStyle>
        13.4. We do not process any form of sensitive data or criminal offense data. Additionally,
        We do not engage in profiling of Personal Data.
      </TextStyle>
      <TitleStyle variant='h5'>14. LINKS TO OTHER SITES</TitleStyle>
      <TextStyle>
        14.1. Our Service may contain links to other sites that are not operated by us. If You click
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        on a third-party link, You will be directed to that third party's site. We strongly advise
        You to review the Privacy Policy of every site You visit. We have no control over and assume
        no responsibility for the content, privacy policies or practices of any third-party sites or
        services.
      </TextStyle>
      <TextStyle>
        14.2. We strongly advise You to review the Privacy Policy of every site You visit. We have
        no control over and assume no responsibility for the content, privacy policies or practices
        of any third—party sites or services.
      </TextStyle>
      <TitleStyle variant='h5'>15. APPLICATION OF THIS PRIVACY POLICY</TitleStyle>
      <TextStyle>
        15.1. This Privacy Policy applies to the Services We provide. However, once You are
        redirected to another Services, website, or app, this Privacy Policy becomes no longer
        applicable.
      </TextStyle>
      <TitleStyle variant='h5'>16. CHANGES AND UPDATES TO OUR PRIVACY POLICY</TitleStyle>
      <TextStyle>
        16.1. At intervals, We may revise this Privacy Policy. We recommend that You periodically
        revisit and review this Privacy Policy to stay informed about the Personal Data We collect,
        how We utilize it, and with whom We may share it.
      </TextStyle>
      <TitleStyle variant='h5'>17. FEEDBACK</TitleStyle>
      <TextStyle>
        17.1. At Users can contact the Company through the Website’s the Company’s contact form, or
        by email at <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link>.
      </TextStyle>
    </WrapperStack>
  );
};
