import { TextStyle, TextBold, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';
import { Link } from '@mui/material';

export const Info = () => {
  return (
    <WrapperStack>
      <TitleStyle variant='h2'>TERMS OF USE</TitleStyle>
      <TitleStyle variant='h5'>Last update: 18/03/2024</TitleStyle>
      <TextStyle>
        You are currently visiting <TextBold>Finlead LTD</TextBold> is a company duly registered
        under the laws of Cyprus, with principal office at Agias Faneromenis, 143-145 Patsias Court,
        Flat/Office 201, Larnaca, Cyprus, which is operated by creditify.ro{' '}
        <TextBold>__________</TextBold>
        <TextBold>
          {' '}
          («Finlead LTD», <TextBold>«Company», «We», «Us», «Our»</TextBold>).
        </TextBold>{' '}
        The interactions between Users and Finlead LTD should be governed by these Terms of Use,
        which may also be referred to as <TextBold>«Agreement».</TextBold>
      </TextStyle>
      <TextStyle>
        {' '}
        Before using the Website, please carefully read these Terms of Use. If there is anything in
        these Terms of Use that You disagree with, please stop using the Website. To understand how
        We process Personal Data, please refer to the «YOUR PRIVACY» Section herein.
      </TextStyle>
      <TitleStyle variant='h5'>1. DEFINITIONS</TitleStyle>
      <TextStyle>
        1.1. <TextBold>Creditor</TextBold> — a separate legal entity (from the Company) engaged in
        microfinance activities, which provides the User with a loan on the terms established by the
        Credit Agreement.
      </TextStyle>
      <TextStyle>
        1.2. <TextBold>Credit Information</TextBold> — data on the desired characteristics of the
        loan provided by the User and used by the Company to prepare the Report, as well as the
        User’s data, including his Personal Data.
      </TextStyle>
      <TextStyle>
        1.3. <TextBold>Personal account</TextBold> — an account created after registering on the
        Website contains information about the User.
      </TextStyle>
      <TextStyle>
        1.4. <TextBold>Report</TextBold> — an electronic document generated by the Company based on
        the User`s Form, containing a list of available offers of Creditors providing credit
        facilities in the region specified by the User and according to the parameters selected by
        the User, based on which the list is created for the User. At the Company’s discretion, it
        may be supplemented with other offers that, in its opinion, may also be of interest to the
        User.
      </TextStyle>
      <TextStyle>
        1.5. <TextBold>User(s)</TextBold> — visitors to the Website, clients of the Company, and
        other people using the Website. Could be addressed as <TextBold>«You», «Your».</TextBold>
      </TextStyle>
      <TextStyle>
        1.6. <TextBold>User Form</TextBold> — page on the Company’s Website where the User must
        enter all relevant registration information to access the Website in accordance with this
        Terms of Use.
      </TextStyle>
      <TextStyle>
        1.7. <TextBold>Website</TextBold> — the website, owned and operated by the Company,
        <TextBold> https://______________</TextBold>.
      </TextStyle>
      <TitleStyle variant='h5'>2. THE SUBJECT MATTER</TitleStyle>
      <TextStyle>
        2.1. By using the Services, You acknowledge that You have read and comprehend these Terms
        and that You agree to them. <TextBold>Service(s)</TextBold> — services that will be provided
        on Our Website includes:
      </TextStyle>
      <TextStyle>
        2.1.1. access to the information service (Company’s Website), which contains information
        about available Creditors’ personal loans, obtaining credit and debit cards, opening bank
        accounts, obtaining mortgage loans, obtaining credit comparison services, services to help
        with debt forgiveness or cancellation, insurance services, obtaining pawnshop services;
      </TextStyle>
      <TextStyle>
        2.1.2. access to the Company’s technical system, available on the Company’s Website,
        allowing the preparation and submission of User Form for review by Creditors;
      </TextStyle>
      <TextStyle>
        2.1.3.actions carried out by the Company, including the collection and processing of
        information from the User’s Form, and providing the User with information about offers from
        Creditors.
      </TextStyle>
      <TextStyle>
        2.2. Please cease using the Services if You disagree with the Terms of this Agreement. It is
        forbidden by the Company for Users to use the Services without the consent to the Terms.
      </TextStyle>
      <TextStyle>
        2.3. Your relationship with Us regarding Your use of the Services, including:
      </TextStyle>
      <TextStyle>
        2.4. You are forbidden to make any speculative, fraudulent, or false requests/Scheduling on
        the Website, nor use it for performance or functionality tests or other competitive
        purposes. Any use that goes against the Terms of the Website or the Company’s legal
        objectives is forbidden.
      </TextStyle>
      <TextStyle>
        2.5. This Agreement shall not be deemed illegal, void, or unenforceable in its entirety if
        any provision of it is found to be so by applicable law, or if such determination is made by
        an appropriate court ruling.
      </TextStyle>
      <TitleStyle variant='h5'>3. SERVICES</TitleStyle>
      <TextStyle>
        3.1. Company provides Your relationship with Us regarding Your use of the Services,
        including:
      </TextStyle>
      <TextStyle>4.1.1. Registration on the Website.</TextStyle>
      <TextStyle>
        4.1.1.1. To access the Service, the User must accept the terms of this Agreement when
        filling out the User Form on the Website and creating a Personal account. The User’s consent
        to the terms of this Agreement when entering their data on the Website means the User’s
        consent with them.
      </TextStyle>
      <TextStyle>
        4.1.1.2.The User fills in the User`s Form on the Website by entering the relevant
        registration information. When entering data on the Website, the User enters his Personal
        Data.
      </TextStyle>
      <TextStyle>
        4.1.1.3.The User must provide truthful, complete, and reliable information and keep it up to
        date. The User must provide his Personal Data in accordance with this paragraph and Privacy
        policy.
      </TextStyle>
      <TextStyle>
        4.1.1.4.The User agrees that he is solely responsible for maintaining the confidentiality of
        the User Form that he uses to access the Website. In addition, the User agrees that he is
        fully responsible to the Company for all actions committed when entering his Personal Data.
      </TextStyle>
      <TextStyle>
        4.1.1.5.If the User becomes aware of any unauthorized use of his Authorization data, he must
        immediately notify the Company by contacting the support service at{' '}
        <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link>.
      </TextStyle>
      <TextStyle>
        4.1.1.6.During registration on the Website, the User will not disclose (or, in case of
        disclosure, bear sole and full responsibility for) name, surname, phone numbers, e-mail
        addresses and other Personal Data or data of third parties without their personal consent to
        such actions explicitly provided by filling out a User form.
      </TextStyle>
      <TextStyle>4.1.2.Report sending</TextStyle>
      <TextStyle>
        4.1.2.1.After the User fills out the User`s Form, he will receive a Report from the Website
        about the list of Creditors and offers by ivr-calls, sending emails, SMS and push messages.
      </TextStyle>
      <TextStyle>
        3.2. Unless otherwise specified, the Company bears responsibility for and uses reasonable
        care to provide the aforementioned Services. Users acknowledge, however, that the Company is
        not a Creditor. Company does not provide credit or similar credit services.
      </TextStyle>
      <TextStyle>
        3.3. Access to the Website may be restricted for any unavailability resulting from events
        beyond the reasonable control of the Company, such as strikes or other labor disputes,
        natural disasters, government actions, floods, fires, earthquakes, civil unrest, terrorist
        attacks, or the Company’s inability to deliver the Services as a result of the negligence of
        third parties (e.g., Internet service provider failure, delay, or denial of service).
      </TextStyle>
      <TextStyle>
        3.4. The Company offers the Services in compliance with the laws and regulations that apply
        to the Company’s provision of Services to its Users generally, subject to the User’s use of
        the Services in accordance with this Agreement, regardless of the particulars of the User’s
        usage of the Services.
      </TextStyle>
      <TextStyle>
        3.5. Regarding how the User uses the Services or any other part of the Services that the
        User provides or receives, the Company has no control over the User’s behavior.
      </TextStyle>
      <TitleStyle variant='h5'>4. RULES OF USAGE</TitleStyle>
      <TextStyle>
        4.1. The User acknowledges that he/she will use the Website in line with these Terms of Use,
        take full responsibility for any actions taken on the Website, and will not use any of the
        Services in any way that:
      </TextStyle>
      <TextStyle>
        4.1.1.is false, misleading, or abusive in any other way; constitutes or encourages criminal
        activity;
      </TextStyle>
      <TextStyle>
        4.1.2. violates applicable laws, rules, and/or regulations; may infringe upon third parties’
        property rights, including, but not limited to, information or materials that infringe upon
        their right to privacy and confidentiality;
      </TextStyle>
      <TextStyle>
        4.1.3.may infringe upon third parties’ property rights, including, but not restricted to,
        data or materials that infringe upon their rights to privacy and confidentiality, trade
        secrets, industrial designs, patents, copyrights, and other legally protected information;
      </TextStyle>
      <TextStyle>
        4.1.4.may interfere with the provision of Services, such as sending feedback to the Company
        or posting any marketing materials—such as spam, phony emails, unsolicited mail, and other
        similar forms of solicitation—on the Website without the Company’s prior written consent;
      </TextStyle>
      <TextStyle>
        4.1.5.The Website must be used by the User for the reasons for which it was intended. Any
        further unauthorized or illegal use of the Website is considered a violation of the Terms,
        for which the offending User will be responsible under the laws that apply;
      </TextStyle>
      <TextStyle>
        4.2.The User warrants that he/she has all legal authority to create a binding legal
        obligation. The User should be at least 18 (eighteen) years of age to use Our Services;
      </TextStyle>
      <TextStyle>4.3.In particular, the User agrees to refrain from the following:</TextStyle>
      <TextStyle>4.3.1.fraudulent acts;</TextStyle>
      <TextStyle>4.3.2.alterations to any Website pages;</TextStyle>
      <TextStyle>4.3.3.unauthorized access to the Website or its code;</TextStyle>
      <TextStyle>
        4.3.4.breaches of the Company’s or any third party’s rights to privacy, other intellectual
        property rights (such as, but not limited to, copyrights, trademarks, and broadcasting
        rights), or other rights;
      </TextStyle>
      <TextStyle>
        4.3.5.No portion of Our Services may be reproduced, altered, transformed into a derivative
        work, downloaded, modified, reverse engineered, emulated, transferred to another service,
        translated, compiled, decompiled, or disassembled.
      </TextStyle>
      <TextStyle>
        4.3.6.Never check, scan, or probe for vulnerabilities in Our Services or any network they
        are connected to;
      </TextStyle>
      <TextStyle>
        4.3.7.Refrain from taking any action that would put an unreasonable or disproportionate
        amount of strain on the systems or networks linked to the Services, the servers hosting the
        Services, or any other network that is involved in their provision, or that would interfere
        with or disrupt any of these servers or networks, or that would breach any guidelines,
        policies, procedures, or other rules governing them;
      </TextStyle>
      <TextStyle>4.3.8.creation of works derived from any sources of data or materials;</TextStyle>
      <TextStyle>4.3.9.impersonate another person;</TextStyle>
      <TextStyle>
        4.3.10. make copies of the site, use its code, or engage in other activities involving the
        code or other Services the Website lists;
      </TextStyle>
      <TextStyle>4.3.11.restrict or prevent any other User from using the Website;</TextStyle>
      <TextStyle>4.3.12.use bots; avoid the security measures;</TextStyle>
      <TextStyle>4.3.13.overload the Website</TextStyle>
      <TextStyle>4.3.14.engage in cyber attacks;</TextStyle>
      <TextStyle>
        4.3.15.interfere in any other manner with the Website’s regular operation;
      </TextStyle>
      <TextStyle>
        4.3.16.utilize programs that could be used to steal, covertly intercept, harm, or otherwise
        tamper with any system, data, or Personal Data;
      </TextStyle>
      <TextStyle>
        4.4. The list of forbidden uses and these guidelines for behavior are not designed to be
        all-inclusive. We reserve the right to decide what behavior We deem to be improper or a
        breach of these Terms, as well as the suitable course of action.
      </TextStyle>
      <TextStyle>
        4.5. You, as the User, acknowledge that it is Your responsibility to abide by any local laws
        that could be relevant to Your use of Our Services.
      </TextStyle>
      <TextStyle>
        4.6. As a User, You are solely responsible for concluding any loan agreements with third
        parties and complying with the requirements of law in Your country.
      </TextStyle>
      <TextStyle>4.7. The User can delete his Personal account in two ways:</TextStyle>
      <TextStyle>
        5.7.1.by sending an email to the corresponding mail (mentioned above - support with a
        request to delete the account);
      </TextStyle>
      <TextStyle>
        5.7.2.specifying the registration number on the relevant pages for unsubscribing:
        creditify.ro/unsubscribe. Specifying there the phone on which the account was registered,
        his account will be deleted <TextBold> within 3 days.</TextBold>
      </TextStyle>
      <TitleStyle variant='h5'>5. YOUR PRIVACY</TitleStyle>
      <TextStyle>
        5.1. When You use Our Website, We automatically collect information about Your interaction
        with the Website. Please, read the Privacy Policy here before accessing the Company’s
        Services.
      </TextStyle>
      <TextStyle>
        5.2. The Privacy Policy is an integral part of these Terms and must be read in conjunction
        with them. If the User disagrees with the Terms, they must stop using the Company’s
        Services.
      </TextStyle>
      <TitleStyle variant='h5'>6. PROPRIETARY RIGHTS</TitleStyle>
      <TextStyle>
        6.1. The Website and all of its components are the exclusive property of the Company.
      </TextStyle>
      <TextStyle>
        6.2. The Company owns all rights, titles, and interests in the Services and all related
        intellectual property rights, with the exception of the restricted rights expressly stated
        in this Agreement. The User is only entitled to the rights specifically granted by this
        Agreement.
      </TextStyle>
      <TextStyle>
        6.3. Except for any Personal Data covered by the Policy, the User acknowledges, understands,
        and consents that the Company is the sole owner of all intellectual property rights. It is
        prohibited by the Terms to utilize any trademarks without permission.
      </TextStyle>
      <TextStyle>
        6.4. By using the Company’s Services, the User grants the Company a perpetual, irrevocable,
        royalty-free license to use and incorporate any comments, feedback, requests for changes,
        suggestions, or other information provided by Users regarding the way the Company operates
        or about the Services itself.
      </TextStyle>
      <TextStyle>
        6.5. It is forbidden to copy any works, including but not limited to articles, video
        content, and videos themselves, as well as the Website’s code and design. It is also
        prohibited to use the Website or its material inappropriately or without authorization in
        accordance with the Terms. Only links to the Company’s Website or the website of a third
        party with authorization to use the Company’s materials may be included with videos and
        articles that are posted on the Website or other third-party websites.
      </TextStyle>
      <TextStyle>
        6.6. The User may instantly lose access to the Website and be held legally responsible in
        line with the applicable laws if he violates the terms of this section. Regarding the
        content posted on the Website, the Company is the exclusive owner of all rights.
      </TextStyle>
      <TitleStyle variant='h5'>7. LINKS TO OTHER SITES</TitleStyle>
      <TextStyle>
        7.1. The Company may place links to other subjects’ websites on the Website. Create links to
        the Company’s pages on other social media platforms, for example, or use other services to
        upload material.
      </TextStyle>
      <TextStyle>
        7.2. We cooperate with third-party service providers, including but not limited to the
        Contractors, who provide Us with services such as website development, hosting of Website,
        maintenance, emailing, marketing, technical support and collateral, information technology
        and cybersecurity services, customer support, and other services. These contractors may
        access the Personal Data or process it on Our behalf as part of their services to Us.
      </TextStyle>
      <TitleStyle variant='h5'>8. DISCLAIMERS AND LIMITATION OF LIABILITY</TitleStyle>
      <TextStyle>
        8.1. The Company aims to provide a convenient Website experience. However, the Company
        disclaims all liability for any improper use of the Website by Users. The material on the
        Website is presented on an «as is» basis, and there may be technical or typographical
        errors. Users are advised not to base any business decisions on the information published on
        the Website, as the Company makes no representations or warranties regarding the accuracy of
        the published data.
      </TextStyle>
      <TextStyle>
        8.2. It is Your personal duty and risk to use this Website. The User’s actions on the
        Website are beyond the Company’s control and responsibility.
      </TextStyle>
      <TextStyle>
        8.3. The Website’s operation in any particular area is not guaranteed by the Company to be
        acceptable, accurate, or accessible for use. Any information published on the Website is
        only correct at the time of publication and may not be correct when the Website is used
        later. It’s possible that the Website is limited to certain nations or languages.
      </TextStyle>
      <TextStyle>
        8.4. THE SERVICES MIGHT NOT BE UNINTERRUPTED OR SECURE. THE USER UNDERSTANDS THAT THERE ARE
        ALWAYS RISKS TO THEIR CONFIDENTIALITY, PRIVACY, AND SECURITY. Consequently, the User agrees
        to waive all claims for circumstances beyond of the Company’s control.
      </TextStyle>
      <TextStyle>
        8.5. The identity of Users shall not be verified by the Company. It is entirely the
        responsibility of Users to ensure that information they contribute is accurate, relevant,
        and private.
      </TextStyle>
      <TextStyle>
        8.6. Websites are created for informational purposes only and do not constitute an offer to
        use the products or services mentioned in this Website.
      </TextStyle>
      <TextStyle>
        8.7. Websites are intended for Users who reside permanently or temporarily in or who have
        access to the country from that country, having reached the legally established age of
        majority in accordance with its legislation: 18 (eighteen) years. For this reason, the
        adequacy of the content and information offered on this website may not be sufficient or
        meet the legal requirements established for access and use from other countries.
      </TextStyle>
      <TextStyle>
        8.8. Finlead LTD is not the Creditor. Therefore, We will never offer funding directly to
        You. We offer online Services. In all cases, We will only give You information about
        Creditors and Report on the basis that they potentially may offer You a loan.We may receive
        a fee from the Creditor for Your introducing with their services.
      </TextStyle>
      <TextStyle>
        8.9. You will never be required to take a loan from any Creditor. We recommend that You
        carefully read the terms of each Creditor to make an informed decision as to whether this
        offer is suitable for You. If in doubt, We recommend that You contact the Creditor before
        agreeing to any such financing.
      </TextStyle>
      <TextStyle>
        8.10. The Company’s obligations under this Agreement shall be limited to the provision of
        Services for the analysis and selection of Credit Information. If the Creditor provides a
        loan or credit to the User, the Company will not be a party to the contract, concluded
        between the User and the Creditor, and, therefore, the Company will not regulate or control
        the compliance of the transaction with the current legislation and standards, terms of the
        transaction, as well as the actions and consequences of the conclusion, execution or
        termination of the contract, including repayment of the loan, and the Company will not take
        into account the User’s claims regarding non-performance (illegal) of the Creditor’s
        obligations under these agreements.
      </TextStyle>
      <TextStyle>
        8.11. The Company does not guarantee the provision of Credit Information by the Creditors
        when the User makes an User Form, but only chooses the options (offers) of the loan. The
        Creditor and User are those who decide on the loan. Credit Information provided by the
        Company in the Report to the User does not constitute an offer of guaranteed provision of
        financial services.
      </TextStyle>
      <TextStyle>
        8.12. The Company will provide Services to the User on an ongoing basis. The Company’s
        actions to implement this Agreement, which are not carried out automatically, will be
        carried out on weekdays.
      </TextStyle>
      <TextStyle>8.13. THE COMPANY MAKES NO WARRANTIES REGARDING:</TextStyle>
      <TextStyle>
        8.13.1. THE SERVICES, RELATED SOFTWARE, RELATED HARDWARE, AND YOUR COMPUTER WILL ALL BE
        COMPATIBLE;
      </TextStyle>
      <TextStyle>
        8.13.2. THE SERVICES’ AVAILABILITY, UNINTERRUPTED OPERATION, ERROR-FREE, OR CORRECTION OF
        ANY ERRORS;
      </TextStyle>
      <TextStyle>
        8.13.3. ON OR THROUGH THE SERVICES, ACCURATE, COMPLETE, SEQUENTIAL, OR TIMELY INFORMATION
        WILL BE AVAILABLE;
      </TextStyle>
      <TextStyle>8.13.4.THE COMPANY WILL STORE ANY USER’S INFORMATION SENT TO IT.</TextStyle>
      <TextStyle>
        8.14. By using the Website, the User agrees to take all necessary security precautions and
        not rely on the Company.
      </TextStyle>
      <TextStyle>
        8.15. The User understands that any Website could experience hacking, virus assaults, data
        manipulation, and other uncontrollable events.
      </TextStyle>
      <TextStyle>
        8.16. Through the use of the Website, the User unconditionally releases the Company from all
        liability and commits to refraining from suing the Company. By using the Website, the User
        agrees not to bring any claims for losses he may have suffered.
      </TextStyle>
      <TitleStyle variant='h5'>9. TERMINATION OF WEBSITE ACCESS</TitleStyle>
      <TextStyle>
        9.1. The Company reserves the right to immediately terminate the User’s access to the
        Website at any time and without prior warning, penalty, or obligation to the User or any
        third party in the event that the User violates the Terms or takes any other actions that
        may result in a violation of the Terms.
      </TextStyle>
      <TextStyle>
        9.2.This remedy shall be used in addition to, and not in lieu of, any other rights or
        remedies that the Company may have in the event that the User violates these Terms. The
        following sections will still be in force in the event that these Terms are terminated for
        any reason: intellectual property, confidentiality, liability limitation, applicable law,
        and dispute resolution.
      </TextStyle>
      <TitleStyle variant='h5'>10. APPLICABLE LAWS AND DISPUTE RESOLUTION</TitleStyle>
      <TextStyle>
        10.1. Any disagreements must be settled by contacting Us to <TextBold> _________</TextBold>;
        disputes shall be resolved within
        <TextBold> 30 (thirty)</TextBold> calendar days by contacting them via email first or by
        using other channels of communication listed in the «Feedback» section.
      </TextStyle>
      <TextStyle>
        10.2. In the event that a dispute cannot be settled, <TextBold> the law of Cyprus</TextBold>{' '}
        should be applied to settle it.
      </TextStyle>
      <TextStyle>
        10.3. This Website is owned and operated by Finlead LTD, a business that is properly
        registered under Cyprus law. This Agreement and any disputes arising out of the use of the
        Services should be governed by the laws <TextBold> of Cyprus.</TextBold> By accepting these
        Terms, You consent to having any legal disputes arising from Your or any third party’s use
        of the Services shall be resolved by the courts <TextBold> of Cyprus</TextBold>, whether
        those disputes are connected to the Agreement.
      </TextStyle>
      <TextStyle>
        10.4. Should any provision or covenant within this Agreement be deemed unenforceable or
        invalid by relevant legal authorities or court rulings, the unenforceability or invalidity
        will not affect the overall enforceability or validity of this Agreement.
      </TextStyle>
      <TitleStyle variant='h5'>11. CHANGES TO THE SERVICES AND WEBSITE</TitleStyle>
      <TextStyle>
        11.1. The Website (or any part of it or related material) may be changed, suspended, or
        discontinued by the Company at any time and for any reason without prior notice, in its sole
        discretion.
      </TextStyle>
      <TextStyle>
        11.2. Before using the Website, the User can be asked to agree to new terms of use (referred
        to as the <TextBold>«Revised Terms»</TextBold>) at any time, at the Company’s sole
        discretion, for any reason, and without incurring any obligations to other Users or parties.
        <TextBold> the law of Cyprus</TextBold> should be applied to settle it.
      </TextStyle>
      <TextStyle>
        11.3. At some point, the Company may, at its sole discretion, limit access to the Website in
        order to perform maintenance and upgrades.
      </TextStyle>
      <TextStyle>
        11.4. Should the Terms change, in order for the User to view certain web pages, access
        certain areas of the Website, or use certain functionalities, the User must accept the
        Revised Terms.
      </TextStyle>
      <TextStyle>
        11.5. The User understands that it is his/her responsibility to stay informed about updates
        to the Website. While the Company makes every effort to notify Users of any updates to the
        Website’s Terms, it is the Users’ responsibility to independently check the Terms each time
        they access the Website. Any modifications to this Agreement shall be complied with by the
        User immediately upon their publication.
      </TextStyle>
      <TextStyle>
        11.6. The Company is not liable to any third party or User for any modification, suspension,
        or termination of the Website or its content.
      </TextStyle>
      <TextStyle>
        11.7. The Company is not liable to any third party or User for any modification, suspension,
        or termination of the Website or its content.
      </TextStyle>
      <TitleStyle variant='h5'>12. FEEDBACK</TitleStyle>
      <TextStyle>
        12.1. Users can contact the Company through the Website’s the Company’s contact form, or by
        email at <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link>.
      </TextStyle>
    </WrapperStack>
  );
};
