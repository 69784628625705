import { Route, Routes } from 'react-router-dom';

import { ROUTES } from '../constants';
import { Loan, Home } from '../pages';
import { About } from '../pages/About';
import { Contacts } from '../pages/Contacts';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { Terms } from '../pages/Terms';
import { FAQ } from '../pages/FAQ';
import { Offerings } from '../pages/Offerings';
import { Unsubscribe } from '../pages/Unsubscribe';
import { Affiliate } from '../pages/Affiliate';
import { CookiesPolicy } from '../pages/CookiesPolicy';
import useScrollToTopOnPageChange from '../hooks/useScrollToTopOnPageChange';

const routes = [
  {
    path: ROUTES.home,
    Element: Home,
  },
  {
    path: ROUTES.loan,
    Element: Loan,
  },
  {
    path: ROUTES.faq,
    Element: FAQ,
  },
  {
    path: ROUTES.about,
    Element: About,
  },
  {
    path: ROUTES.contacts,
    Element: Contacts,
  },
  {
    path: ROUTES.privacyPolicy,
    Element: PrivacyPolicy,
  },
  {
    path: ROUTES.cookiesPolicy,
    Element: CookiesPolicy,
  },
  {
    path: ROUTES.terms,
    Element: Terms,
  },
  {
    path: ROUTES.offerings,
    Element: Offerings,
  },
  {
    path: ROUTES.unsubscribe,
    Element: Unsubscribe,
  },
  {
    path: ROUTES.affiliate,
    Element: Affiliate,
  },
];

export const RouterWrapper = () => {
  useScrollToTopOnPageChange();
  return (
    <Routes>
      {routes.map(({ path, Element }) => (
        <Route key={path} path={path} element={<Element />} />
      ))}
    </Routes>
  );
};
