import { Stack, Typography } from '@mui/material';

import { LoanFormWrapper } from './style';
import { StepOne, StepTwo } from '../Steps';
import { ProgressBar } from '../ProgressBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StepThree } from '../Steps/StepThree';
import { StepFour } from '../Steps/StepFour';
import { useLoanContext } from '../../context/context';
import { use100vh } from 'react-div-100vh';

export const LoanForm = () => {
  const height = use100vh();
  const { step, updateLoanData } = useLoanContext();
  const matches = useMediaQuery('(min-width:600px)');
  const loanSteps = [
    {
      step: 1,
      progress: 25,
      progressLabel: 25,
      Component: StepOne,
    },
    {
      step: 2,
      progress: 50,
      progressLabel: 50,
      Component: StepTwo,
    },
    {
      step: 3,
      progress: 75,
      progressLabel: 75,
      Component: StepThree,
    },
    {
      step: 4,
      progress: 100,
      progressLabel: 99,
      Component: StepFour,
    },
  ];

  const onHandleStep = (stepAction: number) => {
    updateLoanData({ step: (step | 0) + stepAction });
  };
  const currentStep = loanSteps[step | 0];

  return (
    <Stack alignItems='center'>
      {matches && (
        <Typography variant='h2' mt='55px'>
          Your Loan Is Moments Away
        </Typography>
      )}
      <LoanFormWrapper mobileHeight={`${(height || 0) - 64}px`}>
        <ProgressBar
          numberOfSteps={4}
          progressLabel={currentStep.progressLabel}
          value={currentStep.progress}
          step={currentStep.step}
        />
        <currentStep.Component onHandleStep={onHandleStep} />
      </LoanFormWrapper>
    </Stack>
  );
};
