import { styled, Paper, Autocomplete } from '@mui/material';

export const AutocompleteStyle = styled(Autocomplete)(({ theme }) => ({
  zIndex: 9999,
  paddingRight: '0px',
  boxSizing: 'border-box',
  borderRadius: '16px',
  '& .MuiOutlinedInput-root': {
    height: '67px',
  },
  '&.Mui-expanded .MuiOutlinedInput-root': {
    borderWidth: '2px',
    borderRadius: '16px 16px 0px 0px',
    borderColor: theme.palette.black[40],
    '&:hover.Mui-focused': {
      borderBottomWidth: 0,
    },
    '&.Mui-focused': {
      borderBottomWidth: 0,
    },
  },

  input: {
    width: '100%',
  },
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  marginLeft: '-1px',
  width: 'calc( 100% + 2px)',
  borderRadius: '0px 0px 16px 16px',
  border: `2px solid ${theme.palette.black[40]}`,
  borderTopWidth: 0,
  '& .MuiPaper-root': {
    border: `2px solid ${theme.palette.black[40]}`,
  },
  '& .MuiAutocomplete-listbox': {
    padding: '0px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142%',
  },
  '& .MuiAutocomplete-option': {
    padding: '4px 8px !important',
  },
}));
