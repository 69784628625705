import { Stack, styled, Typography } from '@mui/material';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';

export const WrapperStyle = styled(Stack)(() => ({
  zIndex: 2,
  margin: '145px 0 173px',
  alignItems: 'center',
  ['@media(max-width: 1000px)']: {
    margin: '60px 0',
  },
}));
export const GetMoneyButtonStyle = styled(GetMoneyButton)(() => ({
  marginTop: '90px',
  ['@media(max-width: 1000px)']: {
    maxWidth: '412px',
    marginTop: '32px',
  },
}));
export const ListStack = styled(Stack)(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '54px',
  justifyContent: 'center',
  ['@media(max-width: 600px)']: {
    gap: '20px',
  },
}));
export const Title = styled(Typography)(() => ({
  marginBottom: '54px',
  textAlign: 'center',
  ['@media(max-width: 1000px)']: {
    marginBottom: '32px',
  },
}));
