import { Typography } from '@mui/material';
import { GetMoneyButton } from '../GetMoneyButton/style';
import { TypographyStyle, WrapperStack } from './style';
import { AmountAutoComplete } from '../ui/AmountAutoComplete';
import { FormWrapper } from '../ui/FormWrapper/form.style';
import { FormProvider, useForm } from 'react-hook-form';
import { TextLink } from '../TextLink/style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { TOption } from '../../types';
import { useLoan } from '../../hooks';

interface IDataForm {
  amount: TOption;
}
export const QuickForm = () => {
  const { updateLocalLoanData } = useLoan();
  const navigation = useNavigate();
  const amountOptions = [
    { label: 'From 1 000 lei', value: 1000 },
    { label: 'From 3 000 lei', value: 3000 },
    { label: 'From 5 000 lei', value: 5000 },
    { label: 'From 7 000 lei', value: 7000 },
    { label: 'From 10 000 lei', value: 10000 },
  ];
  const methods = useForm<IDataForm>({
    defaultValues: {
      amount: amountOptions[0],
    },
  });
  const onHandleSubmit = (data: IDataForm) => {
    updateLocalLoanData({ amount: String(data.amount.value) });
    navigation(ROUTES.loan);
  };
  return (
    <FormProvider {...methods}>
      <WrapperStack>
        <Typography variant='h3' textAlign='center'>
          Fill in the quick form to get your money
        </Typography>
        <FormWrapper style={{ width: '100%' }} onSubmit={methods.handleSubmit(onHandleSubmit)}>
          <AmountAutoComplete name='amount' options={amountOptions} />
          <GetMoneyButton sx={{ marginTop: 0, width: '100%' }} variant='contained' type='submit'>
            Get money now
          </GetMoneyButton>
        </FormWrapper>
        <TypographyStyle>
          By clicking «Get money now» you agree to the processing of end
          <TextLink variant='text' onClick={() => navigation(ROUTES.privacyPolicy)}>
            Personal Information
          </TextLink>
        </TypographyStyle>
      </WrapperStack>
    </FormProvider>
  );
};
