import { Stack, styled } from '@mui/material';

export const WrapperStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  maxWidth: '510px',
  background: theme.palette.white,
  padding: '62px 40px 65px',
  alignItems: 'center',
  borderRadius: '24px',
  ['@media(max-width: 500px)']: {
    padding: '34px 8px',
  },
}));
