import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { AutocompleteStyle, PaperStyle, TextFieldStyle } from './style';
import { SxProps } from '@mui/system';

type AmountOptionType = {
  label: string;
  value: number;
};
interface IAmountAutoComplete {
  name: string;
  options: AmountOptionType[];
  sx?: SxProps;
}

export const AmountAutoComplete = ({ name, options, sx }: IAmountAutoComplete) => {
  const methods = useFormContext();
  const { control, setValue, getValues } = methods;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleClose();
  }, [setValue]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AutocompleteStyle
      sx={sx}
      {...inputProps}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      value={getValues(name) || null}
      disableClearable
      clearIcon
      onChange={(event, newValue) => {
        setValue(name, newValue);
        handleClose();
      }}
      fullWidth
      options={options || []}
      disableCloseOnSelect
      PaperComponent={(props: React.HTMLAttributes<HTMLDivElement>) => (
        <PaperStyle {...props} elevation={5}>
          {props?.children}
        </PaperStyle>
      )}
      renderTags={() => null}
      renderInput={(params) => (
        <TextFieldStyle
          {...params}
          value={getValues(name) || null}
          inputRef={ref}
          type='text'
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};
