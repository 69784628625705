import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material';
import { hexToRgba } from '../../../../../utils/hexToRgba';

export const TableHeadStyle = styled(TableHead)(({ theme }) => {
  return {
    flex: '0 0 auto',
    width: '100%',
    height: '42px',

    '& tr': {
      paddingRight: '8px',
      borderRadius: '8px',
      backgroundColor: hexToRgba(theme.palette.black[60], 0.1),
    },

    '& th': {
      height: '42px',
      backgroundColor: 'transparent',
      borderBottom: 'none',

      '& p': {
        fontWeight: '700',
        fontSize: '16px',
        color: theme.palette.black[80],
      },
    },
  };
});
