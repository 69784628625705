import { styled, Paper, Autocomplete, TextField } from '@mui/material';

export const AutocompleteStyle = styled(Autocomplete)(({ theme }) => ({
  zIndex: 9999,
  paddingRight: '0px',
  paddingLeft: '0px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  borderRadius: '16px',
  margin: '32px 0 40px',
  '& .MuiOutlinedInput-root': {
    cursor: 'pointer',
    paddingLeft: '24px',
  },
  '&.Mui-expanded .MuiOutlinedInput-root': {
    borderWidth: '2px',
    borderRadius: '16px 16px 0px 0px',
    borderColor: theme.palette.black[40],
    borderBottomWidth: 0,
    paddingLeft: '24px!important',
  },

  input: {
    width: '100%',
  },
  ['@media(max-width: 500px)']: {
    margin: '24px 0 0',
  },
}));

export const TextFieldStyle = styled(TextField)(({ theme }) => ({
  width: '100%',
  border: `0px solid ${theme.palette.black[100]}`,
  borderBottomWidth: 0,
  '&:hover': {
    borderRadius: '0px 0px 16px 16px',
  },
  '&.Mui-expanded': {
    borderWidth: '2px',
    borderRadius: '16px 16px 0px 0px',
    borderColor: theme.palette.black[40],
  },
}));

export const PaperStyle = styled(Paper)(({ theme }) => ({
  marginLeft: '-1px',
  width: 'calc( 100% + 2px)',
  borderRadius: '0px 0px 16px 16px',
  border: `2px solid ${theme.palette.black[40]}`,
  borderTopWidth: 0,
  '& .MuiPaper-root': {
    border: `2px solid ${theme.palette.black[40]}`,
  },
  '& .MuiAutocomplete-listbox': {
    padding: '10px',
    paddingTop: 0,
    fontSize: '18px',
    fontWeight: '500',
  },
  '& .MuiAutocomplete-option': {
    borderRadius: '8px',
    height: '49px',
    padding: '4px 14px !important',
  },
}));
