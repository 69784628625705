import { Typography } from '@mui/material';
import { TextStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';

export const Info = () => {
  return (
    <WrapperStack>
      <Typography variant='h2' textAlign='left'>
        About us
      </Typography>
      <TextStyle>
        Creditify.com.ua was created to help users get access to money without unnecessary hassle
        and wasted time. We understand that sometimes unforeseen situations arise that require quick
        access to cash. That`s why we simplify the process of finding a lender or credit partner to
        get the amount you need. You no longer need to visit numerous websites, fill out multiple
        forms, or wait in line at a local branch. We are not a lender or credit partner, but we
        provide you with a list of lenders or credit partners in our network. The lenders and
        lending partners we work with are ready to provide you with the amount of money you need.
        Each lender or lending partner in our network has simplified the approval process, usually
        with a loan agreement that you can review and sign online. We look forward to helping you
        with your personal credit needs.
      </TextStyle>
    </WrapperStack>
  );
};
