import * as yup from 'yup';
import { CNP } from 'romanian-personal-identity-code-validator';

import {
  INCORRECT_CONDITIONS,
  INCORRECT_EMAIL,
  INCORRECT_PHONE,
  INVALID_CNP,
  REQUIRE_MESSAGE,
} from './messages';

const validateCNP = (value: string) => {
  const cnp = new CNP(value);
  return cnp.isValid();
};
export const loanDataSchema = yup.object().shape({
  email: yup.string().required(REQUIRE_MESSAGE).email(INCORRECT_EMAIL),
  phone: yup.string().required(REQUIRE_MESSAGE).min(10, INCORRECT_PHONE),
  cnp: yup.string().required(REQUIRE_MESSAGE).test('validateCNP', INVALID_CNP, validateCNP),
});
export const loanFullNameSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRE_MESSAGE),
  lastName: yup.string().required(REQUIRE_MESSAGE),
  consent: yup.boolean().required(REQUIRE_MESSAGE).oneOf([true], INCORRECT_CONDITIONS),
});
