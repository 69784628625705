import { SvgIcon } from '@mui/material';

export const FlagCo = () => {
  return (
    <SvgIcon sx={{ width: '110px', height: '80px' }}>
      <svg
        width='110'
        height='79'
        viewBox='0 0 110 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='mask0_1080_3484' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='79'>
          <rect width='110' height='79' rx='12' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_1080_3484)'>
          <rect x='-1' y='-1' width='112' height='81' rx='2' fill='white' />
          <mask
            id='mask1_1080_3484'
            maskUnits='userSpaceOnUse'
            x='-1'
            y='-1'
            width='112'
            height='81'
          >
            <rect x='-1' y='-1' width='112' height='81' rx='2' fill='white' />
          </mask>
          <g mask='url(#mask1_1080_3484)'>
            <g filter='url(#filter0_d_1080_3484)'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M-1 58.4H111V36.8H-1V58.4Z'
                fill='#0748AE'
              />
            </g>
            <g filter='url(#filter1_d_1080_3484)'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M-1 80H111V58.4H-1V80Z'
                fill='#DE2035'
              />
            </g>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M-1 36.8H111V-1H-1V36.8Z'
              fill='#FFD935'
            />
          </g>
        </g>
        <defs>
          <filter
            id='filter0_d_1080_3484'
            x='-1'
            y='36.8'
            width='112'
            height='21.6'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1080_3484' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_1080_3484'
              result='shape'
            />
          </filter>
          <filter
            id='filter1_d_1080_3484'
            x='-1'
            y='58.4'
            width='112'
            height='21.6'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1080_3484' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_1080_3484'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
