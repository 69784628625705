import { Box } from '@mui/material';
import {
  ContentWrapperStyle,
  LeftStackStyle,
  RightStackStyle,
  Title,
  TypographyStyle,
} from './style';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants';
import { AffiliateGirl } from '../../../../assets/img';

export const StartPart = () => {
  const navigate = useNavigate();
  return (
    <ContentWrapperStyle>
      <LeftStackStyle flex={1}>
        <Title variant='h1'>Заробляйте гроші з нашою партнерською програмою</Title>
        <TypographyStyle>Приєднайтесь в один клік щоб отримати найкращі пропозиції</TypographyStyle>
        <GetMoneyButton
          sx={{ padding: '0 32px' }}
          variant='contained'
          onClick={() => navigate(ROUTES.loan)}
        >
          Отримати гроші
        </GetMoneyButton>
      </LeftStackStyle>
      <RightStackStyle>
        <Box component='img' alt='dollar img' src={AffiliateGirl} />
      </RightStackStyle>
    </ContentWrapperStyle>
  );
};
