import React, { ChangeEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { ErrorWrapperStyled, WrapperStyled } from './styled';
import { SxProps } from '@mui/system';
import { ErrorText } from '../ErrorText/style';
import { Box } from '@mui/material';

interface IProps {
  name: string;
  value: string;
  mask: string;
  placeholder: string;
  sx?: SxProps;
}

export function PhoneNumberInput({ name, value, mask, placeholder, sx, ...rest }: IProps) {
  const {
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const cleanValue = e.target.value.replace(/\D/g, '');
    setValue(name, cleanValue);
  };

  const error = errors?.[name]?.message;

  return (
    <WrapperStyled isError={!!error} sx={sx}>
      <ReactInputMask
        value={value}
        onBlur={() => trigger()}
        mask={mask}
        inputMode='numeric'
        placeholder={placeholder}
        onChange={handleChange}
        {...rest}
      />
      {error ? (
        <ErrorWrapperStyled isError={!!error}>
          {!!error && <ErrorText>{error as string}</ErrorText>}
        </ErrorWrapperStyled>
      ) : (
        <Box height='21px' />
      )}
    </WrapperStyled>
  );
}
