import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterWrapper } from './router';
import theme from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { manageQueryParamsInLocalStorage } from './utils/manageQueryParamsInLocalStorage';

function App() {
  const queryClient = new QueryClient();
  useEffect(() => {
    manageQueryParamsInLocalStorage();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RouterWrapper />
        </BrowserRouter>
        <CssBaseline />
        <ToastContainer style={{ zIndex: 10001 }} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
