import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { Info, InfoSecond } from './components';
import { ContentWrapperStyle } from './style';
import TableList, { TableRowProps } from '../../components/ui/TableList';
import { PRIVACY_POLICY_TABLE_OPTIONS } from '../../constants';

const createData = (tableRowData?: TableRowProps) => {
  return {
    personalData: tableRowData?.personalData,
    whenWeCollect: tableRowData?.whenWeCollect,
    howWeUse: tableRowData?.howWeUse,
    whyWeUse: tableRowData?.whyWeUse,
  };
};

const dataObjects = [
  {
    personalData: 'First name, last name, login, password',
    whenWeCollect: 'In registration procedure',
    howWeUse:
      'We use Your Personal Data to register You as a User and identify You in Our Services. Login and password You can use to log in on the Website.',
    whyWeUse: 'Necessary for the performance of a contract with You',
  },
  {
    personalData: 'Email, phone number',
    whenWeCollect: 'In registration procedure',
    howWeUse:
      'Use email to restore Your account. We use it to notify You about changes to Our Terms of Use, Privacy Policy or Cookie Policy; to communicate with You.',
    whyWeUse: 'Necessary for the performance of a contract with You',
  },
  {
    personalData: '',
    whenWeCollect: '',
    howWeUse:
      'We use Your email address to inform You about the latest updates of Our Services and other products You may like. If You have subscribed to Our newsletter, We may send You marketing communications and information that will create an excellent customer experience',
    whyWeUse: 'Only with Your explicit consent',
  },
  {
    personalData: 'First name, last name',
    whenWeCollect: 'In registration procedure',
    howWeUse:
      'We use the Personal Data as part of Our due diligence / KYC procedure on Our “Know Your Customer” KYC portals and to identify You in Our Services',
    whyWeUse: 'Necessary to comply with a legal obligation',
  },
  {
    personalData:
      'Unique identifiers, browser type and settings, device type and settings, operating system, mobile network information. We also collect information about the interaction of Your browsers and devices with Our services, including OS, IP address, URL of Your request',
    whenWeCollect: 'When You use Our Services',
    howWeUse:
      'We use it to optimize Our service and to improve Our Website, Services, direct marketing, User relationships, behavior profiling and experiences',
    whyWeUse: 'Necessary for Our legitimate interests',
  },
];

export const PrivacyPolicy = () => {
  const rows = dataObjects.map((data) => createData(data));
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <Info />
        <TableList rows={rows} headCells={PRIVACY_POLICY_TABLE_OPTIONS} />
        <InfoSecond />
      </ContentWrapperStyle>
      <Footer />
    </Stack>
  );
};
