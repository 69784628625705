import { Typography } from '@mui/material';
import { TextStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';

export const Info = () => {
  return (
    <WrapperStack>
      <Typography variant='h2' textAlign='left'>
        Contacts
      </Typography>
      <TextStyle>
        If you have any questions, please visit our FAQ page where you will find answers to
        frequently asked questions. For loan-related questions, please contact your lender directly.
        If you don`t find the answer on our FAQ page and you are not related to a specific lender,
        you can contact us by email at info@bystro.cash and we will do our best to answer your
        question.
      </TextStyle>
    </WrapperStack>
  );
};
