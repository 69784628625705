import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { ContentWrapperStyle } from './style';
import { Form } from './components';

export const Unsubscribe = () => {
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <Form />
      </ContentWrapperStyle>
      <Footer />
    </Stack>
  );
};
