import { Button, Stack, styled, Typography } from '@mui/material';

export const WrapperStyle = styled(Stack)(({ theme }) => ({
  paddingTop: '44px',
  paddingBottom: '48px',
  marginTop: '90px',
  flexDirection: 'column',
  '& *': {
    fontSize: '18px',
  },
  alignItems: 'start',
  borderTop: `1px solid ${theme.palette.black[10]}`,
  ['@media(max-width: 1220px)']: {
    alignItems: 'start',
    '& > *': {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  ['@media(max-width: 1000px)']: {
    marginTop: '40px',
  },
  ['@media(max-width: 800px)']: {
    alignItems: 'center',
  },
  ['@media(max-width: 480px)']: {
    paddingTop: '34px',
    paddingBottom: '32px',
    '& *': {
      fontSize: '16px',
    },
  },
}));
export const LinkContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',

  '& *': {
    color: theme.palette.black[100],
  },
  rowGap: '23px',
  columnGap: '42px',
  ['@media(max-width: 1220px)']: {
    marginBottom: '29px',
  },
  ['@media(max-width: 1000px)']: {
    flexWrap: 'wrap',
    marginBottom: 0,
    '& > *': {
      marginBottom: '16px',
    },
  },
  ['@media(max-width: 480px)']: {
    padding: '0 55px',
    gap: '16px',
  },
  ['@media(max-width: 400px)']: {
    columnGap: '10px',
  },
}));
export const LinkButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.black[100],
  whiteSpace: 'nowrap',
  ['@media(max-width: 820px)']: {
    '&:nth-child(1)': {
      order: 1,
    },
    '&:nth-child(2)': {
      order: 5,
    },
    '&:nth-child(3)': {
      order: 2,
    },
    '&:nth-child(4)': {
      order: 3,
    },
    '&:nth-child(5)': {
      order: 4,
    },
    '&:nth-child(6)': {
      order: 6,
    },
  },
}));
export const WrapperCopyrightStack = styled(Stack)(() => ({
  flexDirection: 'row',
  marginTop: '30px',
  gap: '30px',
  ['@media(max-width: 800px)']: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
export const CopyrightStack = styled(Stack)(() => ({
  flexDirection: 'row',
  ['@media(max-width: 800px)']: {
    '& *': {
      fontSize: '16px',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
export const CopyrightTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[40],
  whiteSpace: 'nowrap',
  fontSize: '18px',
  fontWeight: '500',
  ['@media(max-width: 800px)']: {
    fontSize: '16px',
  },
}));
