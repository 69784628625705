import { Stack, useTheme } from '@mui/material';
import { Header } from '../../components/ui/Header';
import { FinancialDisclosureAndTerms } from '../../components/FinancialDisclosureAndTerms';
import { Footer } from '../../components/ui/Footer';
import { LoanForm } from './components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LoanProvider } from './context/context';
import { useLoan } from '../../hooks';
import { useState } from 'react';
import { ILoanData } from '../../types';

export const Loan = () => {
  const theme = useTheme();
  const { currentData, updateLocalLoanData } = useLoan();
  const [loanData, setLoanData] = useState<ILoanData>(currentData);
  const updateLoanData = (newData: Partial<ILoanData>) => {
    setLoanData((prevState) => {
      updateLocalLoanData({ ...prevState, ...newData });
      return { ...prevState, ...newData };
    });
  };
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Stack>
      <Header bgColor={!matches ? theme.palette.white : 'transparent'} />
      <LoanProvider {...loanData} updateLoanData={updateLoanData}>
        <LoanForm />
      </LoanProvider>
      <FinancialDisclosureAndTerms />
      <Footer />
    </Stack>
  );
};
