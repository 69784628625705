import { Box, Typography } from '@mui/material';
import { WrapperStyle } from './style';
import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';
import { Map } from '../../../../assets/img';

export const Question = () => {
  return (
    <ContentWrapper>
      <Box
        component='img'
        alt='dollar img'
        src={Map}
        sx={{ width: '100%', position: 'absolute', bottom: 0, zIndex: 1 }}
      />
      <WrapperStyle>
        <Typography variant='h3' color='white'>
          Залишились питання — напишіть нам
        </Typography>
        <Typography variant='h3' color='turquoise'>
          creditify@gmail.com
        </Typography>
      </WrapperStyle>
    </ContentWrapper>
  );
};
