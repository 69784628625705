import { axiosClient, axiosClientUnsubscribe } from './index';
import { queryParamsToString } from '../utils/queryParamsToString';
import { getTrackingParams } from '../utils/getTrackingParams';
import { API_ENDPOINTS } from '../constants';

interface RequestParams {
  country: string;
  amount: string;
  id_number: string;
  phone: string;
  name: string;
  land: string;
  loan_reason: string;
  email: string;
  utm_source?: string | null;
  utm_campaign?: string | null;
  utm_adgroup?: string | null;
  subid2?: string | null;
  rev_aff?: string | null;
  cid?: string;
  fbc?: string;
  fbp?: string;
  pixel_id?: string | null;
}

export async function sendGetRequest(params: RequestParams) {
  return await axiosClient.get(API_ENDPOINTS.ADD_LEAD + queryParamsToString(params));
}

export async function checkEmailRequest({ email }: { email: string }) {
  return await axiosClient.post(API_ENDPOINTS.CHECK_EMAIL, { email });
}
export async function getCardsRequest() {
  const { crm_id } = getTrackingParams();
  return await axiosClient.get(API_ENDPOINTS.GET_CARDS + `&crm_id=${crm_id}`);
}
export async function getActionSubId(name: string) {
  const { crm_id, subid, utm_source, utm_campaign } = getTrackingParams();
  const queryParams =
    crm_id || subid
      ? `?crm_id=${crm_id || subid}&action=click_offer&ref=Finance&offer=${name}` +
        queryParamsToString({ utm_source, utm_campaign }, true)
      : '';
  return await axiosClient.get(API_ENDPOINTS.ACTION_SUB_ID + queryParams);
}
export async function unsubscribeRequest(data: FormData) {
  return axiosClientUnsubscribe.post(API_ENDPOINTS.unsubscribe, data);
}
