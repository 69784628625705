import React from 'react';
import { ContentWrapper } from '../ContentWrapper/style';
import {
  CopyrightStack,
  CopyrightTypography,
  LinkButton,
  LinkContainer,
  WrapperCopyrightStack,
  WrapperStyle,
} from './style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { useTheme } from '@mui/material';

export const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigationOptions = [
    { title: 'About', path: ROUTES.about },
    { title: 'FAQs', path: ROUTES.faq },
    { title: 'Contacts', path: ROUTES.contacts },
    { title: 'Privacy Policy', path: ROUTES.privacyPolicy },
    { title: 'Cookies Policy', path: ROUTES.cookiesPolicy },
    { title: 'Terms of Use', path: ROUTES.terms },
  ];
  return (
    <ContentWrapper>
      <WrapperStyle>
        <LinkContainer>
          {navigationOptions.map(({ title, path }, index) => (
            <LinkButton key={index} variant='text' onClick={() => navigate(path)}>
              {title}
            </LinkButton>
          ))}
        </LinkContainer>
        <WrapperCopyrightStack>
          <CopyrightStack>
            <CopyrightTypography>Copyright © 2024 CREDITIFY. </CopyrightTypography>
            <CopyrightTypography> All Rights Reserved</CopyrightTypography>
          </CopyrightStack>
          <LinkButton
            variant='text'
            sx={{ color: theme.palette.black[60] }}
            onClick={() => navigate(ROUTES.unsubscribe)}
          >
            Unsubscribe from the newsletter
          </LinkButton>
        </WrapperCopyrightStack>
      </WrapperStyle>
    </ContentWrapper>
  );
};
