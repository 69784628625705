import { Stack, styled } from '@mui/material';
import { ContentWrapper } from '../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(({ theme }) => ({
  marginTop: '55px',
  flexDirection: 'row',
  paddingBottom: '80px',
  flexWrap: 'wrap',
  borderBottom: `1px solid ${theme.palette.black[10]}`,
  ['@media(max-width: 1000px)']: {
    marginTop: '46px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const FormWrapperStyle = styled(Stack)(() => ({
  flex: 1,
  alignItems: 'flex-end',
  ['@media(max-width: 1000px)']: {
    marginTop: '32px',
    alignItems: 'center',
  },
}));
