import { styled, Box, Stack } from '@mui/material';

interface IWrapperStyleProps {
  isActive?: boolean;
}
interface ICheckboxStyleProps {
  isActive?: boolean;
}
export const WrapperStyle = styled(Stack)<IWrapperStyleProps>(({ theme, isActive }) => ({
  padding: '0 16px',
  cursor: 'pointer',
  marginBottom: '16px',
  alignItems: 'center',
  borderRadius: '16px',
  background: isActive ? theme.palette.turquoise : theme.palette.black[5],
  flexDirection: 'row',
  height: '55px',
  minHeight: '55px',
  width: '100%',
  '&:hover': {
    background: isActive ? theme.palette.turquoise : theme.palette.black[10],
  },
}));

export const CheckboxStyle = styled(Box)<ICheckboxStyleProps>(({ theme, isActive }) => ({
  height: isActive ? '11px' : '23px',
  width: isActive ? '11px' : '23px',
  boxSizing: 'content-box',
  marginRight: '12px',
  border: isActive ? `6px solid ${theme.palette.white}` : 'none',
  borderRadius: '16px',
  background: isActive ? theme.palette.turquoise : theme.palette.black[35],
}));
