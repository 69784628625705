import { useQuery } from 'react-query';
import { getCardsRequest } from '../../axios/api';

interface IOptions {
  onError: (error: Error) => void;
}

export const useGetCards = (options: IOptions) => {
  return useQuery('GetCards', getCardsRequest, {
    ...options,
    refetchOnWindowFocus: false,
  });
};
