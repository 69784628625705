import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { FinancialDisclosureAndTerms } from '../../components/FinancialDisclosureAndTerms';
import { Stack } from '@mui/material';
import { Info } from './components';
import { QuickForm } from '../../components/QuickForm';
import { ContentWrapperStyle, FormWrapperStyle } from './style';

export const About = () => {
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <Info />
        <FormWrapperStyle>
          <QuickForm />
        </FormWrapperStyle>
      </ContentWrapperStyle>
      <FinancialDisclosureAndTerms />
      <Footer />
    </Stack>
  );
};
