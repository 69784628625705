import { Stack, styled, Typography } from '@mui/material';

export const WrapperStyle = styled(Stack)(({ theme }) => ({
  marginTop: '124px',
  borderRadius: '32px',
  backgroundColor: theme.palette.black[100],
  padding: '63px 80px 72px',
  ['@media(max-width: 1000px)']: {
    marginTop: '22px',
    padding: '63px 80px 72px',
  },
  ['@media(max-width: 600px)']: {
    marginTop: '22px',
    padding: '29px 35px 41px',
  },
}));
export const ListStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '60px',
  '& > *:nth-child(1),& >*:nth-child(2)': {
    padding: '0 60px 0 0',
    borderRight: `1px solid ${theme.palette.black[40]}`,
  },
  ['@media(max-width: 1000px)']: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    '& > *:nth-child(1),& >*:nth-child(2)': {
      padding: '0 0 20px 0',
      borderRight: 0,
      borderBottom: `1px solid ${theme.palette.black[40]}`,
    },
  },
}));
export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '78px',
  color: theme.palette.white,
  textAlign: 'center',
  ['@media(max-width: 1000px)']: {
    marginBottom: '37px',
  },
}));
