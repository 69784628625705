import { BorderLinearProgress } from './style';
import { Stack, Typography, useTheme } from '@mui/material';

interface IProps {
  progressLabel: number;
  value: number;
  step: number;
  numberOfSteps: number;
}
export const ProgressBar = ({
  progressLabel,
  value = progressLabel,
  step,
  numberOfSteps,
}: IProps) => {
  const theme = useTheme();
  return (
    <Stack>
      <Stack flexDirection='row' justifyContent={'space-between'}>
        <Typography>
          Step {step} <span style={{ color: theme.palette.black[60] }}>from {numberOfSteps}</span>
        </Typography>
        <Typography>{progressLabel}%</Typography>
      </Stack>
      <BorderLinearProgress variant='determinate' value={value} />
    </Stack>
  );
};
