import { Stack, Typography } from '@mui/material';
import { GetMoneyButtonStyle, ListStack, Title, WrapperStyle } from './style';

import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';
import { ROUTES } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { FlagCo, FlagMx, FlagPh, FlagUa, FlagVn } from '../../../../assets/icons';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FlagRo } from '../../../../assets/icons/FlagRo';

export const HowItWorks = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const itemsList = [
    {
      title: 'Україна',
      Icon: FlagUa,
    },
    {
      title: 'Філіппіни',
      Icon: FlagPh,
    },
    {
      title: 'Вʼєтнам',
      Icon: FlagVn,
    },
    {
      title: 'Мексика',
      Icon: FlagMx,
    },
    {
      title: 'Колумбія',
      Icon: FlagCo,
    },
    {
      title: 'Румунія',
      Icon: FlagRo,
    },
  ];
  return (
    <ContentWrapper>
      <WrapperStyle>
        <Title variant='h2'>Де ми працюємо?</Title>
        <ListStack>
          {itemsList.map(({ Icon, title }, index) => (
            <Stack key={index} alignItems='center'>
              <Icon />
              <Typography variant='h3' mt='32px' textAlign='start'>
                {title}
              </Typography>
            </Stack>
          ))}
        </ListStack>
        <GetMoneyButtonStyle
          sx={{ marginTop: matches ? '90px' : '32px' }}
          variant='contained'
          onClick={() => navigate(ROUTES.loan)}
        >
          Приєднатись зараз
        </GetMoneyButtonStyle>
      </WrapperStyle>
    </ContentWrapper>
  );
};
