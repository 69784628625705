import { styled, Typography } from '@mui/material';

export const TextStyle = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  fontSize: '16px',
  color: theme.palette.black[60],
  ['@media(max-width: 1000px)']: {
    fontSize: '14px',
    marginTop: '15px',
  },
}));

export const TitleStyle = styled(Typography)(() => ({
  marginTop: '40px',
  ['@media(max-width: 1000px)']: {
    marginTop: '30px',
  },
}));
export const TextBold = styled('span')(({ theme }) => ({
  fontWeight: '500',
  color: theme.palette.black[80],
}));
