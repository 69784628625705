import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { Advantages, HowItWorks, StartPart } from './components';
import { FinancialDisclosureAndTerms } from '../../components/FinancialDisclosureAndTerms';

export const Home = () => {
  return (
    <Stack>
      <Header />
      <StartPart />
      <Advantages />
      <HowItWorks />
      <FinancialDisclosureAndTerms />
      <Footer />
    </Stack>
  );
};
