import { Button, Stack, Typography } from '@mui/material';
import { OptionsList } from '../../../../../components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLoanContext } from '../../../context/context';
import { useState } from 'react';
import useScrollToTopOnPageChange from '../../../../../hooks/useScrollToTopOnPageChange';

interface IProps {
  onHandleStep: (stepAction: number) => void;
}
export const StepOne = ({ onHandleStep }: IProps) => {
  useScrollToTopOnPageChange();
  const stepOneOptions = [
    { title: 'From 1 000 lei', value: '1000' },
    { title: 'From 3 000 lei', value: '3000' },
    { title: 'From 5 000 lei', value: '5000' },
    { title: 'From 7 000 lei', value: '7000' },
    { title: 'From 10 000 lei', value: '10000' },
  ];
  const { amount, updateLoanData } = useLoanContext();
  const defaultIndexActiveOption = stepOneOptions.findIndex((option) => option?.value === amount);
  const [currentAmount, setCurrentAmount] = useState<string>(
    stepOneOptions[defaultIndexActiveOption >= 0 ? defaultIndexActiveOption : 1].value,
  );
  const matches = useMediaQuery('(min-width:600px)');

  const onHandleChange = (value: string | number) => {
    setCurrentAmount(String(value));
  };

  const onHandleNext = () => {
    onHandleStep(1);
    updateLoanData({ amount: currentAmount });
  };
  return (
    <>
      <Stack flex={1} justifyContent='center'>
        <Typography variant='h3' sx={{ margin: '0 -16px 22px' }} textAlign='center'>
          How much money do you need?
        </Typography>
        <OptionsList
          options={stepOneOptions}
          defaultIndexActiveOption={defaultIndexActiveOption >= 0 ? defaultIndexActiveOption : 1}
          onHandleChange={onHandleChange}
        />
      </Stack>
      <Stack alignItems='end'>
        <Button
          variant='contained'
          sx={{ width: matches ? 'fit-content' : '100%' }}
          endIcon={<ArrowForwardIcon />}
          onClick={onHandleNext}
        >
          Next
        </Button>
      </Stack>
    </>
  );
};
