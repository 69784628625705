import { SvgIcon } from '@mui/material';

export const FlagPh = () => {
  return (
    <SvgIcon sx={{ width: '110px', height: '80px' }}>
      <svg
        width='110'
        height='79'
        viewBox='0 0 110 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='mask0_1080_2128' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='79'>
          <rect width='110' height='79' rx='12' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_1080_2128)'>
          <rect
            x='-0.75'
            y='0.25'
            width='110.5'
            height='78.7857'
            rx='1.75'
            fill='white'
            stroke='#F5F5F5'
            strokeWidth='0.5'
          />
          <mask
            id='mask1_1080_2128'
            maskUnits='userSpaceOnUse'
            x='-1'
            y='0'
            width='111'
            height='80'
          >
            <rect
              x='-0.75'
              y='0.25'
              width='110.5'
              height='78.7857'
              rx='1.75'
              fill='white'
              stroke='white'
              strokeWidth='0.5'
            />
          </mask>
          <g mask='url(#mask1_1080_2128)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M-1 79.2856H110V36.9999H48.333L51.857 39.6429L-1 79.2856Z'
              fill='#DD1C34'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M110 37H48.3333L-1 0H110V37Z'
              fill='#0D4BC3'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M6.92826 15.8572C8.38787 15.8572 9.57112 14.6739 9.57112 13.2143C9.57112 11.7547 8.38787 10.5715 6.92826 10.5715C5.46865 10.5715 4.2854 11.7547 4.2854 13.2143C4.2854 14.6739 5.46865 15.8572 6.92826 15.8572ZM38.6424 42.2859C40.1021 42.2859 41.2853 41.1026 41.2853 39.643C41.2853 38.1834 40.1021 37.0001 38.6424 37.0001C37.1828 37.0001 35.9996 38.1834 35.9996 39.643C35.9996 41.1026 37.1828 42.2859 38.6424 42.2859ZM9.57112 66.0714C9.57112 67.531 8.38787 68.7143 6.92826 68.7143C5.46865 68.7143 4.2854 67.531 4.2854 66.0714C4.2854 64.6118 5.46865 63.4286 6.92826 63.4286C8.38787 63.4286 9.57112 64.6118 9.57112 66.0714ZM16.9661 44.902L12.4428 51.8513L14.1583 43.7387C13.8822 43.5132 13.6294 43.2604 13.404 42.9843L5.29128 44.6998L12.2406 40.1765C12.223 40.001 12.214 39.823 12.214 39.6429C12.214 39.4628 12.223 39.2848 12.2406 39.1094L5.29128 34.586L13.4039 36.3015C13.6294 36.0255 13.8822 35.7726 14.1583 35.5472L12.4428 27.4345L16.9661 34.3838C17.1416 34.3662 17.3196 34.3572 17.4997 34.3572C17.6798 34.3572 17.8578 34.3662 18.0333 34.3838L22.5566 27.4345L20.8411 35.5471C21.1172 35.7726 21.37 36.0254 21.5955 36.3015L29.7081 34.586L22.7588 39.1094C22.7764 39.2848 22.7854 39.4628 22.7854 39.6429C22.7854 39.823 22.7764 40.001 22.7588 40.1765L29.7081 44.6998L21.5955 42.9844C21.37 43.2604 21.1172 43.5132 20.8411 43.7387L22.5566 51.8513L18.0332 44.902C17.8578 44.9196 17.6798 44.9286 17.4997 44.9286C17.3196 44.9286 17.1416 44.9196 16.9661 44.902Z'
              fill='#FDD64D'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
