import { Button, styled } from '@mui/material';

export const GetMoneyButton = styled(Button)(() => ({
  height: '70px',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 32px',
  marginTop: '50px',
  textWrap: 'nowrap',
  ['@media(max-width: 1000px)']: {
    marginTop: '20px',
    width: '100%',
  },
}));
