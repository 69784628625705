import { TextStyle, TextBold, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';
import { Link } from '@mui/material';

export const Info = () => {
  return (
    <WrapperStack>
      <TitleStyle variant='h2'>PRIVACY POLICY</TitleStyle>
      <TitleStyle variant='h5'>Last update: 18/03/2024</TitleStyle>
      <TextStyle>
        You are currently visiting{' '}
        <TextBold> Finlead LTD («Finlead LTD», «Company», «We», «Us», «Our»)</TextBold> Website,
        creditify.ro <TextBold>____________</TextBold>.The interactions between Users and{' '}
        <TextBold>Finlead LTD,</TextBold> concerning Personal Data processing, should be governed by
        this Privacy Policy.
      </TextStyle>
      <TextStyle>
        {' '}
        This Privacy Policy outlines the methods by which We gather and utilize the Personal Data
        You furnish to Us via the Website usage. It elucidates the options at Your disposal
        concerning Our handling of Your Personal Data and provides details on accessing the
        information. We hold Your privacy in high regard and approach its safeguarding with utmost
        seriousness.
      </TextStyle>
      <TitleStyle variant='h5'>1. GENERAL INFORMATION</TitleStyle>
      <TextStyle>
        1.1. This Privacy Policy describes how We collect and use the Personal Data.
      </TextStyle>
      <TextStyle>
        1.2. Privacy Policy applies to persons who use Our Website and hence provide Personal Data
        to Us. It also describes the choices available to You regarding Our use of Your Personal
        Data and how You can access the information. We respect Your privacy, and We take protecting
        it seriously.
      </TextStyle>
      <TextStyle>
        1.3. Please read this Privacy Policy carefully. By using the Website, You confirm that You
        understand and agree to the conditions of this Privacy Policy («Consent»). If You do not
        Consent to such conditions, You can choose not to visit the Website.
      </TextStyle>
      <TextStyle>
        1.4. Once You submit Your User Form on the Website You give Your consent for Finlead LTD to
        contact You along with Our group of Creditors regarding Your User Form. On this basis, We
        process Your Personal Data.
      </TextStyle>
      <TextStyle>
        1.5. If You wish to withdraw Your consent to the processing of the Personal Data for
        marketing purposes, You can do so at any time. Simply click the «Unsubscribe» option in any
        SMS or marketing email sent by us or any related third party. You can also withdraw Your
        consent by sending an email to a{' '}
        <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link>. Withdrawal of
        pre-granted consent to the processing of Personal Data will not affect the legality of the
        processing that We performed before the withdrawal of Your consent
      </TextStyle>
      <TextStyle>
        1.6. IThis Privacy Policy should be read in conjunction with the Terms of Use and Cookie
        Policy, which is incorporated herein by reference. All definitions used in the Privacy
        Policy and not defined herein have the same meanings as those defined in the Terms of Use
        and Cookie Policy.
      </TextStyle>
      <TitleStyle variant='h5'>2. DATA CONTROLLER</TitleStyle>
      <TextStyle>
        2.1. <TextBold>Data Controller</TextBold> — an individual, whether natural or legal, who
        chooses how and why any Personal Data is or will be processed (alone, jointly, or in common
        with other individuals).
      </TextStyle>
      <TextStyle>
        2.2.{' '}
        <TextBold>
          Finlead LTD (a company duly registered under the laws of the Cyprus, with principal office
          at Agias Faneromenis, 143—145 Patsias Court, Flat/Office 201, Larnaca, Cyprus)
        </TextBold>{' '}
        is the Data Controller that is responsible for the Personal Data collected and used within
        the scope of this Privacy Policy.
      </TextStyle>
      <TitleStyle variant='h5'>3. DATA PROCESSING PRINCIPLES</TitleStyle>
      <TextStyle>
        3.1. We adhere to the following principles in order to protect Your privacy:
      </TextStyle>
      <TextStyle>
        3.1.1. <TextBold>principle of purposefulness</TextBold> — We process Personal Data fairly
        and transparently only for the achievement of determined and lawful objectives, and they
        shall not be processed in a manner not conforming to the objectives of data processing;
      </TextStyle>
      <TextStyle>
        3.1.2. <TextBold>principle of minimalism</TextBold> — We collect Personal Data only to the
        extent necessary for the achievement of determined purposes and do not keep Personal Data if
        it is no longer needed;
      </TextStyle>
      <TextStyle>
        3.1.3. <TextBold>principle of data quality</TextBold> — We update Personal Data shall be
        up—to—date, complete, and necessary for the achievement of the purpose of data processing;
      </TextStyle>
      <TextStyle>
        3.1.4. <TextBold>principle of security</TextBold> — security measures shall be applied in
        order to protect Personal Data from unauthorized or unlawful processing and against
        accidental loss, destruction, or damage, using appropriate technical and organizational
        measures;
      </TextStyle>
      <TextStyle>
        3.1.5. <TextBold>principle of individual participation</TextBold> — the persons shall be
        notified of data collected concerning him or her, the persons shall be granted access to the
        data concerning him or her and the persons have the right to demand the correction of
        inaccurate or misleading data.
      </TextStyle>
      <TitleStyle variant='h5'>4. INFORMATION THAT WE GATHER AND HANDLE</TitleStyle>
      <TextStyle>
        4.1. Under this Privacy Policy We collect Your Personal Data. For the purposes of providing
        Our Services and conducting a preliminary assessment, the User agrees that We may transfer
        Personal Data to third parties, including but not limited to Creditors.
      </TextStyle>
      <TextStyle>
        4.2. <TextBold>Personal Data</TextBold> includes, but not limited to:
      </TextStyle>
    </WrapperStack>
  );
};
