import { SvgIcon } from '@mui/material';

export const LogoIcon = () => {
  return (
    <SvgIcon sx={{ width: '146.92px', height: '19.48px' }}>
      <svg
        width='143'
        height='19'
        viewBox='0 0 143 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M138.787 0.313934L136.101 4.72245H140.314L143 0.313934H138.787Z' fill='#75DCC3' />
        <path
          d='M128.879 0.313934H124.321L131.506 12.1018V18.6486H135.793V11.747L128.879 0.313934Z'
          fill='#1B2A3A'
        />
        <path
          d='M122.794 3.7261V0.313934H108.789V18.6486H113.08V11.9744H121.663V8.56227H113.08V3.7261H122.794Z'
          fill='#1B2A3A'
        />
        <path d='M101.673 0.313934V18.6486H105.964V0.313934H101.673Z' fill='#1B2A3A' />
        <path d='M76.6193 0.313934V18.6486H80.9061V0.313934H76.6193Z' fill='#1B2A3A' />
        <path
          d='M63.9889 0.313934H55.5717V18.6486H63.9889C70.0465 18.6486 74.2137 15.0363 74.2137 9.48128C74.2137 3.92628 70.0465 0.313934 63.9889 0.313934ZM63.8003 15.1591H59.8769V3.78523H63.8003C67.48 3.78523 69.8855 5.95083 69.8855 9.46308C69.8855 12.9753 67.48 15.1591 63.8003 15.1591Z'
          fill='#1B2A3A'
        />
        <path
          d='M42.9966 15.2501V11.0054H51.6024V7.70696H42.9966V3.7261H52.7338V0.313934H38.7282V18.6486H53.0788V15.2501H42.9966Z'
          fill='#1B2A3A'
        />
        <path
          d='M83.2104 0.313941V3.7625H89.1439V18.635H93.4352V3.7625H99.3687V0.300293L83.2104 0.313941Z'
          fill='#1B2A3A'
        />
        <path
          d='M32.4268 12.757C33.5956 12.302 34.5919 11.4979 35.277 10.4565C35.9621 9.4152 36.3018 8.18849 36.249 6.94719C36.249 2.85259 33.1397 0.300293 28.1906 0.300293H20.169V18.635H24.4604V3.7625H27.9606C30.5824 3.7625 31.9071 4.93628 31.9071 6.94719C31.9071 8.95809 30.5824 10.1319 27.9606 10.1319H26.038L31.9806 18.6213H36.5802L32.4268 12.757Z'
          fill='#1B2A3A'
        />
        <path
          d='M10.2754 15.3502C6.79813 15.3502 4.33277 12.9435 4.33277 9.48127C4.33277 6.01906 6.79813 3.61235 10.2754 3.61235C11.1626 3.60765 12.0398 3.79831 12.8433 4.17052C13.6468 4.54272 14.3565 5.08714 14.9209 5.76428L17.6807 3.24838C15.9374 1.15558 13.2789 0 10.0546 0C4.28677 0 0 3.95356 0 9.48127C0 15.009 4.28678 18.9625 10.0454 18.9625C13.2651 18.9625 15.9374 17.8161 17.6807 15.6914L14.9209 13.171C14.3644 13.8588 13.6569 14.4125 12.852 14.7901C12.0472 15.1676 11.1661 15.3592 10.2754 15.3502Z'
          fill='#1B2A3A'
        />
      </svg>
    </SvgIcon>
  );
};
