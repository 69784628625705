import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { InfoFirst, InfoSecond } from './components';
import { ContentWrapperStyle } from './style';
import TableList, { TableRowProps } from '../../components/ui/TableList';
import { COOKIES_POLICY_TABLE_OPTIONS } from '../../constants';

const createData = (tableRowData?: TableRowProps) => {
  return {
    name: tableRowData?.name,
    purpose: tableRowData?.purpose,
    expiry: tableRowData?.expiry,
    type: tableRowData?.type,
    party: tableRowData?.party,
  };
};

const dataObjects = [
  {
    name: 'CookieConsent',
    purpose: 'Saves the User’s cookie consent state for the current domain.',
    expiry: '442 months',
    type: 'Strictly necessary cookies',
    party: 'FIRST-PARTY',
  },
  {
    name: 'hex (32)',
    purpose: 'Used to manage server calls to the Website’s backend systems.',
    expiry: '30 days',
    type: 'Strictly necessary cookies',
    party: 'FIRST-PARTY',
  },
  {
    name: 'rc::a',
    purpose:
      'This cookie helps to identify between humans and bots. This cookie is required for the security of the Website.',
    expiry: 'Persistent',
    type: 'Strictly necessary cookies',
    party: 'THIRD-PARTY',
  },
  {
    name: 'rc::c',
    purpose:
      'This cookie is used to distinguish between humans and bots. This cookie is essential for security of the Website.',
    expiry: 'Session',
    type: 'Strictly necessary cookies',
    party: 'THIRD-PARTY',
  },
  {
    name: 'CONSENT',
    purpose:
      'Used to determine whether the visitor accepted the marketing category in the cookie banner. This cookie is required for GDPR compliance on the Website.',
    expiry: '2 years',
    type: 'Strictly necessary cookies',
    party: 'THIRD-PARTY',
  },
  {
    name: '_gid',
    purpose:
      'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.',
    expiry: 'Session',
    type: 'Performance',
    party: 'FIRST-PARTY',
  },
  {
    name: 'jv_history_#',
    purpose:
      'Contains information on User navigation, interaction, and time spent on the Website and its sub-pages. This information is used to improve the relevancy of adverts and for statistical analysis.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_store_#_app',
    purpose: 'Used to store performed actions on the Website.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_store_#_client',
    purpose:
      'Registers statistical data on Users’ behavior on the Website. Used for internal analytics by the Website operator.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_temp_sess_enter_ts_#',
    purpose:
      'Sets a timestamp for when the visitor entered the website. This is used for analytical purposes on the website.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_temp_sess_pages_count_#',
    purpose: 'Used for internal analytics by the Website operator.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_temp_visits_count_#',
    purpose:
      'Collects anonymous information about User visits to the Website, such as the number of visits, average time spent on the website, and pages loaded, in order to generate reports for Website content optimisation.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'jv_loader_info_#',
    purpose: 'Used to keep technical information required to load the widget.',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'collect',
    purpose:
      'Used to send data to Google Analytics about the visitor’s device and behavior. Tracks the visitor across devices and marketing channels.',
    expiry: 'Session',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: '_ga',
    purpose:
      'Registers a unique ID that is used to collect statistical data about how visitors use the Website.',
    expiry: '2 years',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: '_ga_#',
    purpose:
      'Used by Google Analytics to collect data on the number of times a User has visited the Website as well as dates for the first and most recent visit.',
    expiry: '2 years',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: '_gat',
    purpose: 'Used by Google Analytics to throttle request rate.',
    expiry: '1 day',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'ads/ga-audiences',
    purpose:
      'Used to determine whether the User intends to leave the Website using cursor movements. This enables the Website to display specific pop-ups to keep users on the site or convert them into buyers.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'LAST_RESULT_ENTRY_KEY',
    purpose: 'Stores the User’s video player preferences using an embedded YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'LogsDatabaseV2|#LogsRequestsStore',
    purpose:
      'Required for the development and functionality of YouTube video content on the website. It reduces the time required to load data from a Website.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'nextId',
    purpose: 'Stores information about the video to be shown after the current video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'remote_sid',
    purpose:
      'Required for the development and functionality of YouTube video content on the Website.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'requests',
    purpose:
      'Necessary for the implementation and functionality of YouTube video-content on the Website.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'ServiceWorkerLogsDatabase#SWHealthLog',
    purpose: '',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'TESTCOOKIESENABLED',
    purpose:
      'Necessary for the implementation and functionality of YouTube video-content on the Website.',
    expiry: '1 day',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'VISITOR_INFO1_LIVE',
    purpose: 'Tries to estimate the Users’ bandwidth on pages with integrated YouTube videos.',
    expiry: '180 days',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'YSC',
    purpose: 'Registers a unique ID to keep track of what YouTube videos the User has viewed.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt.innertube::nextId',
    purpose:
      'Registers a unique ID to keep statistics of what videos from YouTube the User has seen.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'ytidb::LAST_RESULT_ENTRY_KEY',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'YtIdbMeta#databases',
    purpose: 'Setting options are saved after clicking on the YouTube video player.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-cast-available',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-cast-installed',
    purpose: 'Stores the User’s video player preferences using the linked YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-connected-devices',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-device-id',
    purpose: 'Stores the User’s video player preferences using the linked YouTube video.',
    expiry: 'Persistent',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-fast-check-period',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-session-app',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'yt-remote-session-name',
    purpose: 'Stores the User’s video player preferences using embedded YouTube video.',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
  {
    name: 'PROXYSID',
    purpose:
      'It is necessary for remembering the authorization in the Personal Account, creating an order. Stores information about the unique identifier of the site user session.',
    expiry: 'Session',
    type: 'Strictly necessary cookies',
    party: 'FIRST-PARTY',
  },
  {
    name: 'jv_sync_#',
    purpose: '',
    expiry: 'Persistent',
    type: 'Performance',
    party: 'THIRD-PARTY',
  },
  {
    name: 'BITRIX_PR_VSLK_HISTORY',
    purpose: '',
    expiry: '23 months',
    type: 'Strictly necessary cookies',
    party: 'THIRD-PARTY',
  },
  {
    name: 'td',
    purpose: '',
    expiry: 'Session',
    type: 'Marketing',
    party: 'THIRD-PARTY',
  },
];

export const CookiesPolicy = () => {
  const rows = dataObjects.map((data) => createData(data));
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <InfoFirst />
        <TableList rows={rows} headCells={COOKIES_POLICY_TABLE_OPTIONS} />
        <InfoSecond />
      </ContentWrapperStyle>
      <Footer />
    </Stack>
  );
};
