import { styled } from '@mui/material';
import { ContentWrapper } from '../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(() => ({
  marginTop: '55px',
  flexDirection: 'column',
  paddingBottom: '80px',
  ['@media(max-width: 1000px)']: {
    marginTop: '46px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
