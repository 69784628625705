import { styled } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';

export const TableRowStyle = styled(TableRow)({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
});

export const TableCellStyle = styled(TableCell)(({ theme }) => {
  return {
    height: '74px',
    padding: '0 20px',
    fontWeight: 500,
    color: theme.palette.black[80],
  };
});
export const TableStyle = styled(Table)({
  overflowX: 'auto',
  display: 'flex',
  flexFlow: 'column',
  width: '1248px',
  height: '100%',
  borderCollapse: 'separate',
});
