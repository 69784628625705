import { SelectOption } from './components/SelectOption';
import { useState } from 'react';
import { ISelectOption } from './types';
import { OptionsListWrapper } from './style';

interface IProps {
  options: ISelectOption[];
  defaultIndexActiveOption?: number;
  onHandleChange: (value: string | number) => void;
}
export const OptionsList = ({ options, defaultIndexActiveOption = 0, onHandleChange }: IProps) => {
  const [currentOption, setCurrentOption] = useState<ISelectOption>(
    options[defaultIndexActiveOption],
  );
  const onHandleSelect = (value: number | string) => {
    onHandleChange(value);
    setCurrentOption(options.find((option) => value === option?.value) || options[0]);
  };
  return (
    <OptionsListWrapper>
      {options.map(({ title, value }, index) => (
        <SelectOption
          title={title}
          isActive={value === currentOption?.value}
          value={value}
          key={index}
          onHandleSelect={onHandleSelect}
        />
      ))}
    </OptionsListWrapper>
  );
};
