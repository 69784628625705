import { Stack, Typography, Button } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { BackButton } from '../../../../../components/BackButton/style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormWrapper } from '../../../../../components/ui/FormWrapper/form.style';

import { yupResolver } from '@hookform/resolvers/yup';
import { loanFullNameSchema } from '../../../../../validation/loan.schema';
import { sendGetRequest } from '../../../../../axios/api';
import { TextFieldRHF } from '../../../../../components';
import { getTrackingParams } from '../../../../../utils/getTrackingParams';
import { ROUTES } from '../../../../../constants';
import { CheckboxRHF } from '../../../../../components/ui/CheckboxRHF/CheckboxRHF';
import { TextLink } from '../../../../../components/TextLink/style';
import { ButtonWrapper } from '../../LoanForm/style';
import { useLoanContext } from '../../../context/context';
import { useNavigate } from 'react-router-dom';
import useScrollToTopOnPageChange from '../../../../../hooks/useScrollToTopOnPageChange';

interface IProps {
  onHandleStep: (stepAction: number) => void;
}
interface IDataForm {
  firstName: string;
  lastName: string;
  consent: boolean;
}
export const StepFour = ({ onHandleStep }: IProps) => {
  useScrollToTopOnPageChange();
  const { firstName, lastName, amount, loanReason, email, phone, cnp, updateLoanData } =
    useLoanContext();
  const navigate = useNavigate();
  const { utm_source, utm_campaign, utm_adgroup, subid2, rev_aff, cid, fbc, fbp, pixel_id } =
    getTrackingParams();
  const methods = useForm<IDataForm>({
    resolver: yupResolver(loanFullNameSchema),
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      consent: false,
    },
  });
  const onHandleSubmit = (data: IDataForm) => {
    updateLoanData({ firstName: data.firstName, lastName: data.lastName });
    sendGetRequest({
      amount: amount,
      loan_reason: loanReason,
      email: email,
      name: data.firstName + ' ' + data.lastName,
      phone: phone,
      land: 'creditify.ro',
      country: 'ro',
      id_number: cnp,
      utm_source,
      utm_campaign,
      utm_adgroup,
      subid2,
      rev_aff,
      cid,
      fbc,
      fbp,
      pixel_id,
    }).then(({ data }) => {
      document.cookie = 'crm_id=' + data.data?.subid + ';path=/';
      navigate(ROUTES.offerings);
    });
  };

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={methods.handleSubmit(onHandleSubmit)}>
        <Stack flex={1} justifyContent='center'>
          <Typography variant='h3' sx={{ margin: '0 -16px 22px' }} textAlign='center'>
            Your Full Name
          </Typography>
          <TextFieldRHF name='firstName' type='text' placeholder='Your name' />
          <TextFieldRHF name='lastName' type='text' placeholder='Your last name' />
          <Stack flexDirection='row'>
            <CheckboxRHF name='consent' />
            <Typography ml='10px'>
              I consent to process{' '}
              <TextLink
                sx={{ height: '20px !important' }}
                variant='text'
                onClick={() => navigate(ROUTES.privacyPolicy)}
              >
                Personal Information
              </TextLink>
            </Typography>
          </Stack>
        </Stack>
        <ButtonWrapper>
          <BackButton
            variant='contained'
            startIcon={<ArrowBackIcon />}
            onClick={() => onHandleStep(-1)}
          >
            Back
          </BackButton>

          <Button variant='contained' type='submit'>
            Send
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </FormProvider>
  );
};
