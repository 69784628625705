import { SvgIcon } from '@mui/material';
import { SxProps } from '@mui/system';

interface IDollarsRainIcon {
  sx?: SxProps;
}
export const DollarsRainHorizontalIcon = ({ sx }: IDollarsRainIcon) => {
  return (
    <SvgIcon sx={{ width: '100%', height: '100%', ...sx }}>
      <svg
        width='315'
        height='167'
        viewBox='0 0 315 167'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M293.264 133.365L279.793 155.383H300.922L314.393 133.365H293.264Z'
          fill='#75DCC3'
        />
        <path d='M97.3274 122L89 135.84H102.061L110.389 122H97.3274Z' fill='#75DCC3' />
        <path d='M-0.0585232 122L-27 166.665H15.2574L42.199 122H-0.0585232Z' fill='#75DCC3' />
        <path d='M294.8 113L282.309 133.76H301.902L314.393 113H294.8Z' fill='#75DCC3' />
        <path d='M55.7157 39.5547L42 62.2016H63.5129L77.2286 39.5547H55.7157Z' fill='#75DCC3' />
        <path d='M81.9655 21L70.4541 39.8725H88.5096L100.021 21H81.9655Z' fill='#75DCC3' />
        <path d='M256.037 0L247.71 13.8398H260.771L269.099 0H256.037Z' fill='#75DCC3' />
      </svg>
    </SvgIcon>
  );
};
