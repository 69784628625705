import { manageQueryParamsInLocalStorage } from './manageQueryParamsInLocalStorage';

function getCookies() {
  const cookies: { [key: string]: string } = {};
  document.cookie.split(';').forEach((cookie) => {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    cookies[name] = value;
  });
  return cookies;
}
export function getTrackingParams() {
  const queryParams = manageQueryParamsInLocalStorage();
  const utm_source = queryParams?.['utm_source'];
  const utm_campaign = queryParams?.['utm_campaign'];
  const utm_adgroup = queryParams?.['utm_adgroup'];
  const subid = queryParams?.['subid'];
  const subid2 = queryParams?.['subid2'];
  const sub_id_19 = queryParams?.['sub_id_19 '];
  const rev_aff = queryParams?.['affid'];
  const pixel_id = queryParams?.['fbpixel'];
  const cookies = getCookies();
  const cid = cookies['_ga']?.split('.')[2];
  const fbc = cookies['_fbc'];
  const fbp = cookies['_fbp'];
  const crm_id = cookies['crm_id'];

  return {
    utm_source,
    utm_campaign,
    utm_adgroup,
    subid,
    subid2,
    sub_id_19,
    rev_aff,
    cid,
    fbc,
    fbp,
    pixel_id,
    crm_id,
    otherQueryParams: { ...queryParams },
  };
}
