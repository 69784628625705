import { Stack, styled, Typography } from '@mui/material';

export const WrapperStyle = styled(Stack)(() => ({
  marginTop: '180px',
  ['@media(max-width: 1000px)']: {
    marginTop: '60px',
  },
}));
export const ListStack = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: '24px',
  justifyContent: 'center',
  ['@media(max-width: 1000px)']: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
  },
}));
export const ItemStack = styled(Stack)(({ theme }) => ({
  borderRadius: '20px',
  width: '300px',
  height: '300px',
  padding: '35px 0 40px',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.white,
}));
export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '43px',
  color: theme.palette.black[100],
  textAlign: 'center',
  ['@media(max-width: 1000px)']: {
    marginBottom: '16px',
  },
}));
