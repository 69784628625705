import { Card } from '../Card/Card';
import { toast } from 'react-toastify';
import { TCard } from '../../../../types';
import { useGetCards } from '../../../../hooks';
import { RequestHandler } from '../../../../components/RequestHandler/RequestHandler';
import { useEffect, useState } from 'react';

export function CardsList() {
  const [cards, setCards] = useState<TCard[]>([]);
  const { isLoading, data } = useGetCards({
    onError(error: Error) {
      toast.error(error.message);
    },
  });

  const perPage = parseInt(
    (document.querySelector('meta[name="website-per-page"]')?.getAttribute('content') ||
      '15') as string,
  );
  useEffect(() => {
    if (data?.data?.data?.entities) {
      setCards(data?.data?.data?.entities.splice(0, perPage));
    }
  }, [data]);
  return (
    <RequestHandler isLoading={isLoading}>
      {cards?.map((card: TCard) => {
        return <Card key={card.id} card={card} />;
      })}
    </RequestHandler>
  );
}
