import { Box, Typography } from '@mui/material';
import { ContentWrapperStyle, LeftStackStyle, RightStackStyle, TypographyStyle } from './style';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DollarsRainIcon, DollarsRainHorizontalIcon } from '../../../../assets/icons';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants';
import { Lei } from '../../../../assets/img';

export const StartPart = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:1000px)');
  return (
    <ContentWrapperStyle>
      <LeftStackStyle flex={1}>
        <Typography variant='h1'>Get a Loan Today for a Better Tomorrow</Typography>
        <TypographyStyle>
          As is customary, the shareholders of the largest companies, which represent a prime
          example of the continental-European type of political culture, will be made public.
        </TypographyStyle>
        <GetMoneyButton
          sx={{ padding: '0 32px', margin: 0 }}
          variant='contained'
          onClick={() => navigate(ROUTES.loan)}
        >
          Get money now
        </GetMoneyButton>
      </LeftStackStyle>
      <RightStackStyle>
        {matches ? <DollarsRainIcon /> : <DollarsRainHorizontalIcon />}
        <Box component='img' alt='dollar img' src={Lei} />
      </RightStackStyle>
    </ContentWrapperStyle>
  );
};
