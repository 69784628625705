import { Stack, styled, Typography } from '@mui/material';
import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(() => ({
  marginTop: '98px',
  flexDirection: 'row',
  ['@media(max-width: 1400px)']: {
    marginTop: '22px',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    padding: 0,
  },
}));
export const Title = styled(Typography)(() => ({
  textAlign: 'left',
  ['@media(max-width: 1400px)']: {
    marginTop: '24px',
    textAlign: 'center',
  },
}));
export const TypographyStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[60],
  marginTop: '20px',
  fontSize: '22px',
  fontWeight: '500',
  ['@media(max-width: 1000px)']: {
    textAlign: 'center',
    fontSize: '16px',
  },
}));

export const LeftStackStyle = styled(Stack)(() => ({
  flex: 1,
  maxWidth: '637px',
  alignItems: 'start',
  ['@media(max-width: 1440px)']: {
    alignItems: 'center',
    padding: '0 16px',
    maxWidth: '100%',
  },
}));
export const RightStackStyle = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '100%',
  flex: 1,
  height: '568px',

  '& .MuiBox-root': {
    height: '568px',
    width: '736px',
  },

  ['@media(max-width: 1000px)']: {
    overflow: 'hidden',
    '& .MuiBox-root': {
      height: '251px',
      width: '329px',
    },
  },
}));
