import { TextStyle, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';

export const InfoSecond = () => {
  return (
    <WrapperStack>
      <TitleStyle variant='h4'>How to get money?</TitleStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders.
      </TextStyle>
      <TitleStyle variant='h4'>Creditify.mx is not a lender and does not make?</TitleStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders.
      </TextStyle>
      <TitleStyle variant='h4'>Your lender will have its own rates and fees?</TitleStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders.
      </TextStyle>
      <TitleStyle variant='h4'>Our service is always free-there is never?</TitleStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders.
      </TextStyle>
    </WrapperStack>
  );
};
