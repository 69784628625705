import { Stack, styled } from '@mui/material';

export const WrapperStyle = styled(Stack)(({ theme }) => ({
  background: theme.palette.black[100],
  zIndex: 2,
  borderRadius: '32px',
  height: '94px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '32px',
  flexDirection: 'row',
  ['@media(max-width: 800px)']: {
    flexDirection: 'column',
    padding: '0 34px',
    textAlign: 'center',
    height: '145px',
    gap: '16px',
  },
}));
