import { styled } from '@mui/material';

export const BoxStyle = styled('div')(() => ({
  position: 'fixed',
  zIndex: 11111,
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  marginLeft: 0,
  background: 'rgba(0, 0, 0, 0)',
  borderRadius: '24px',
  backgroundColor: 'hsla(0, 0%, 100%, 0.35)',
  transition: 'opacity 0.3s, visibility 0.3s',
}));

export const WrapperStyle = styled('div')(() => ({
  zIndex: 1111,
  fontSize: '18px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const LoadBlockStyle = styled('div')(({ theme }) => ({
  fontSize: 'inherit',
  textAlign: 'center',

  '& span': {
    display: 'inline-block',
    width: '1em',
    height: '1em',
    borderRadius: '100%',
    backgroundColor: theme.palette.pearlAqua,
    animation: 'sk-bouncedelay 1.4s ease-in-out infinite both',

    '&:nth-child(1)': {
      animationDelay: '-0.32s',
    },

    '&:nth-child(2)': {
      animationDelay: '-0.16s',
    },
  },

  '@-webkit-keyframes sk-bouncedelay': {
    '0%, 80%, to': {
      WebkitTransform: 'scale(0)',
      transform: 'scale(0)',
      opacity: 0.35,
    },
    '40%': {
      WebkitTransform: 'scale(1)',
      transform: 'scale(1)',
      opacity: 0.95,
    },
  },
  '@keyframes sk-bouncedelay': {
    '0%, 80%, to': {
      WebkitTransform: 'scale(0)',
      transform: 'scale(0)',
      opacity: 0.35,
    },
    '40%': {
      WebkitTransform: 'scale(1)',
      transform: 'scale(1)',
      opacity: 0.95,
    },
  },
}));
