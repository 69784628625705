export const PRIVACY_POLICY_TABLE_OPTIONS = [
  { id: 'personalData', label: 'Personal Data', minWidth: '100px' },
  { id: 'whenWeCollect', label: 'When We collect', minWidth: '100px' },
  { id: 'howWeUse', label: 'How We use', minWidth: '200px' },
  { id: 'whyWeUse', label: 'Why We use', minWidth: '100px' },
];
export const COOKIES_POLICY_TABLE_OPTIONS = [
  { id: 'name', label: 'NAME', minWidth: '200px' },
  { id: 'purpose', label: 'PURPOSE', minWidth: '300px' },
  { id: 'expiry', label: 'EXPIRY', minWidth: '100px' },
  { id: 'type', label: 'TYPE', minWidth: '100px' },
  { id: 'party', label: 'FIRST-PARTY or THIRD-PARTY cookies?', minWidth: '300px' },
];
