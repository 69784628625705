import Typography from '@mui/material/Typography';
import { TableHeadStyle } from './style';
import { TableCellStyle, TableRowStyle } from '../../style';
import { ITableOption } from '../../index';

interface ITableListHeader {
  headCells: Array<ITableOption>;
}

export const TableListHeader = ({ headCells }: ITableListHeader) => {
  return (
    <TableHeadStyle>
      <TableRowStyle>
        {headCells.map((column) => (
          <TableCellStyle key={column.id} width={column.minWidth}>
            <Typography variant='body2'>{column.label}</Typography>
          </TableCellStyle>
        ))}
      </TableRowStyle>
    </TableHeadStyle>
  );
};
