import {
  CardHighlightStyle,
  CardInnerWrapStyle,
  CardWrapStyle,
  ImgLinkStyle,
  ImgStyle,
  ImgWrapStyle,
  OfferListItemStyle,
  OfferListItemTitleStyle,
  OfferListItemValStyle,
  OfferListStyle,
  PureLinkPinkStyle,
  PureLinkStyle,
} from './style';
import { TCard } from '../../../../types';
import { queryParamsToString } from '../../../../utils/queryParamsToString';
import { getTrackingParams } from '../../../../utils/getTrackingParams';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';
import { getActionSubId } from '../../../../axios/api';

export function Card({ card }: { card: TCard }) {
  const {
    name,
    label,
    label_color,
    link,
    logo,
    consideration,
    max_sum_guest,
    description,
    characteristic_link,
    characteristic,
    notice_link,
    notice,
    get_money,
  } = card.attributes;
  const { utm_source, crm_id, cid, sub_id_19, otherQueryParams } = getTrackingParams();
  function extractValue(html: any) {
    return html.match(/<li>(.*?)<\/li>/)[1];
  }

  const linkWithParams =
    link + queryParamsToString({ utm_source, sub_id_19, crm_id, cid, ...otherQueryParams });
  const onHandleClick = () => {
    getActionSubId(name);
    window.open(linkWithParams, '_blank');
  };
  return (
    <CardWrapStyle>
      <CardInnerWrapStyle>
        <CardHighlightStyle background={label_color}>{label}</CardHighlightStyle>
        <ImgWrapStyle>
          <ImgLinkStyle href={link} target='_blank' rel='noopener'>
            <ImgStyle src={logo} alt={name} />
          </ImgLinkStyle>
        </ImgWrapStyle>
        <OfferListStyle>
          <OfferListItemStyle>
            <OfferListItemTitleStyle>SUMA</OfferListItemTitleStyle>
            <OfferListItemValStyle>{max_sum_guest}</OfferListItemValStyle>
          </OfferListItemStyle>
          <OfferListItemStyle>
            <OfferListItemTitleStyle>PERIOADA</OfferListItemTitleStyle>
            <OfferListItemValStyle>{extractValue(description)}</OfferListItemValStyle>
          </OfferListItemStyle>
          <OfferListItemStyle>
            <OfferListItemTitleStyle>TIMP DE APROBARE</OfferListItemTitleStyle>
            <OfferListItemValStyle>{consideration}</OfferListItemValStyle>
          </OfferListItemStyle>
        </OfferListStyle>
        <PureLinkStyle href={characteristic_link} target='_blank' rel='noopener'>
          {characteristic}
        </PureLinkStyle>
        <PureLinkPinkStyle href={notice_link} target='_blank' rel='noopener'>
          {notice}
        </PureLinkPinkStyle>
        <GetMoneyButton variant='contained' onClick={onHandleClick} sx={{ minWidth: '100%' }}>
          {get_money}
        </GetMoneyButton>
      </CardInnerWrapStyle>
    </CardWrapStyle>
  );
}
