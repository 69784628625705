import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { FinancialDisclosureAndTerms } from '../../components/FinancialDisclosureAndTerms';
import { Stack } from '@mui/material';
import { ContentWrapperStyle } from './style';
import { CardsList } from './components/CardsList/CardsList';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLoan } from '../../hooks';

export const Offerings = () => {
  const { currentData } = useLoan();
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <Stack alignItems='center'>
          <Typography variant='h3' marginBottom='10px' textAlign='center'>
            Good afternoon, {currentData.firstName}!
          </Typography>
          <Typography variant='h2' marginBottom={matches ? '60px' : '30px'}>
            Our best deals
          </Typography>
        </Stack>
        <CardsList />
      </ContentWrapperStyle>
      <FinancialDisclosureAndTerms />
      <Footer />
    </Stack>
  );
};
