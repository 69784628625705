import { styled, Checkbox } from '@mui/material';

export const CheckboxStyle = styled(Checkbox)(() => ({
  padding: '0px',
  width: '20px',
  height: '20px',
  marginTop: '2px',
  marginBottom: 'auto',
  '& .MuiBox-root': { width: '20px', height: '20px' },
}));
