import { createTheme } from '@mui/material/styles';
import palette from './palette';

const theme = createTheme({
  ...palette,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: (props) => ({
          fontFamily: 'Inter',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          color: props.theme.palette.black[100],
          ['@media(max-width: 1000px)']: {
            fontSize: '16px',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: () => ({
            fontWeight: '500',
            fontSize: '80px',
            ['@media(max-width: 1000px)']: {
              fontSize: '38px',
              textAlign: 'center',
            },
          }),
        },
        {
          props: { variant: 'h2' },
          style: () => ({
            fontWeight: '500',
            fontSize: '60px',
            lineHeight: '72px',
            ['@media(max-width: 1000px)']: {
              fontSize: '28px',
              lineHeight: '38px',
              textAlign: 'center',
            },
          }),
        },
        {
          props: { variant: 'h3' },
          style: () => ({
            fontWeight: '500',
            fontSize: '32px',
            lineHeight: '39px',
            ['@media(max-width: 1000px)']: {
              fontSize: '24px',
              lineHeight: '29px',
            },
          }),
        },
        {
          props: { variant: 'h4' },
          style: () => ({
            fontWeight: '500',
            fontSize: '22px',
            lineHeight: '27px',
            ['@media(max-width: 1000px)']: {
              fontSize: '18px',
              lineHeight: '26px',
            },
          }),
        },
        {
          props: { variant: 'h5' },
          style: () => ({
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '26px',
            ['@media(max-width: 1000px)']: {
              fontSize: '18px',
              lineHeight: '26px',
            },
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: (props) => ({
          fontFamily: 'Inter',
          fontSize: '22px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '26px',
          color: props.theme.palette.white,
          borderRadius: '500px',
          background: props.theme.palette.lavender[60],
          textTransform: 'capitalize',
          '&:hover': {
            background: props.theme.palette.lavender[80],
          },
          '&:active': {
            background: props.theme.palette.lavender[100],
          },
          width: 'fit-content',
        }),
      },
      variants: [
        {
          props: { variant: 'text' },
          style: (props) => ({
            disableRipple: true,
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '12px',
            color: props.theme.palette.black[80],
            padding: 0,
            fontWeight: '500',
            textTransform: 'none',
            width: 'fit-content',
            minWidth: 'auto',
            background: 'none',
            '&:hover': {
              background: 'none',
            },
          }),
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          // background: 'red',
          '& .MuiFormControl-root': {
            background: 'green',
          },
          '& .MuiFormHelperText-root': {
            margin: '0!important',
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiOutlinedInput-root': {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px',
            borderRadius: '16px',
            color: theme.palette.black[100],
            background: theme.palette.white,
            border: `1px solid ${theme.palette.black[20]}`,

            '& fieldset': {
              border: 'none',
            },
            '& input ': {
              padding: '20px 0 25px 27px',
              height: '22px',
            },
            '&:hover': {
              border: `1px solid ${theme.palette.black[40]}`,
            },
            '&:hover.Mui-focused': {
              background: theme.palette.white,
              border: `2px solid ${theme.palette.black[40]}`,
            },
            '&.Mui-focused': {
              background: theme.palette.white,
              border: `2px solid ${theme.palette.black[40]}`,
            },
            '&.Mui-error, &.Mui-focused.Mui-error': {
              borderColor: theme.palette.red,
              zIndex: 0,
            },
            '&.Mui-success': {
              borderColor: theme.palette.goldenrod,
            },
          },
        }),
      },
      variants: [
        {
          props: { variant: 'outlined', type: 'number' },
          style: () => ({
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              ' -webkit-appearance': 'none',
              margin: 0,
            },
          }),
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingRight: '0px',
          boxSizing: 'border-box',
          '&.Mui-expanded': {
            borderWidth: '2px',
            borderRadius: '16px 16px 0px 0px',
            borderColor: theme.palette.black[40],
          },
          input: {
            width: '100%',
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background-color: rgba(234, 255, 250, 1);
          background-image: url(/images/main-background-desktop.png);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
       .scrollhost::-webkit-scrollbar {
         display: none;
        }
        
        /* Скрытие тумблера прокрутки для Firefox */
        .scrollhost {
         scrollbar-color: transparent transparent;
        }
        
        /* Скрытие тумблера прокрутки для IE и Edge */
        .scrollhost {
         -ms-overflow-style: none;
        }
      `,
    },
  },
});

export default theme;
