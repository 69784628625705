import React, { createContext, ReactElement, useContext } from 'react';
import { ILoanData } from '../../../types';

type TProps = TLoanContext & {
  children: ReactElement;
};

type TLoanContext = {
  step: number;
  amount: string;
  loanReason: string;
  email: string;
  phone: string;
  cnp: string;
  firstName: string;
  lastName: string;
  updateLoanData: (newData: Partial<ILoanData>) => void;
};

const LoanContext = createContext<TLoanContext | null>(null);

export function LoanProvider({
  step,
  amount,
  loanReason,
  email,
  phone,
  cnp,
  firstName,
  lastName,
  updateLoanData,
  children,
}: TProps) {
  return (
    <LoanContext.Provider
      value={{ step, amount, loanReason, email, phone, cnp, firstName, lastName, updateLoanData }}
    >
      {children}
    </LoanContext.Provider>
  );
}

export const useLoanContext = () => {
  const context = useContext(LoanContext);
  if (!context) {
    throw new Error('Loan context not found.');
  }
  return context;
};
