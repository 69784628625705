import { Box, Stack, Typography } from '@mui/material';
import { AffiliateImg1, AffiliateImg2, AffiliateImg3 } from '../../../../assets/img';
import { ItemStack, ListStack, Title, WrapperStyle } from './style';

import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';

export const Advantages = () => {
  const itemsList = [
    {
      title: 'CPL/CPA модель',
      img: AffiliateImg1,
    },
    {
      title: 'Зручний спосіб оплати',
      img: AffiliateImg2,
    },
    {
      title: 'Якісний трафік високі виплати',
      img: AffiliateImg3,
    },
  ];
  return (
    <ContentWrapper>
      <WrapperStyle>
        <Stack>
          <Title variant='h2'>Наші переваги</Title>
          <ListStack>
            {itemsList.map(({ img, title }, index) => (
              <ItemStack key={index} flex={1} maxWidth='320px'>
                <Stack alignItems='center'>
                  <Box
                    component='img'
                    alt='dollar img'
                    src={img}
                    sx={{ height: '150px', width: '150px' }}
                  />
                </Stack>
                <Typography variant='h3' mt='16px' textAlign='center'>
                  {title}
                </Typography>
              </ItemStack>
            ))}
          </ListStack>
        </Stack>
      </WrapperStyle>
    </ContentWrapper>
  );
};
