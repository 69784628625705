import { LOCAL_STORAGE_KEY } from '../constants';
import { ILoanData } from '../types';

export const useLoan = () => {
  const savedData = localStorage.getItem(LOCAL_STORAGE_KEY.LOAN_DATA);

  const currentData = savedData
    ? JSON.parse(savedData)
    : {
        step: 0,
        amount: '',
        loanReason: '',
        email: '',
        phone: '',
        cnp: '',
        firstName: '',
        lastName: '',
      };

  const updateLocalLoanData = (newData: Partial<ILoanData>) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.LOAN_DATA,
      JSON.stringify({ ...currentData, ...newData }),
    );
  };

  return { currentData, updateLocalLoanData };
};
