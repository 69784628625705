import { Button, styled } from '@mui/material';

export const BackButton = styled(Button)(({ theme }) => ({
  background: theme.palette.black[40],
  '&:hover': {
    background: theme.palette.black[70],
  },
  '&:active': {
    background: theme.palette.black[90],
  },
}));
