import { Stack, styled, Typography } from '@mui/material';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';

export const WrapperStyle = styled(Stack)(() => ({
  margin: '124px 0',
  borderRadius: '32px',
  alignItems: 'center',
  ['@media(max-width: 1000px)']: {
    margin: '22px 0 16px',
  },
}));
export const GetMoneyButtonStyle = styled(GetMoneyButton)(() => ({
  ['@media(max-width: 1000px)']: {
    maxWidth: '412px',
  },
}));
export const ListStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '10px',
  ['@media(max-width: 1000px)']: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
}));
export const ImgContainer = styled(Stack)(() => ({
  alignItems: 'center',
  height: '141px',
  '& img': {
    height: '100%',
  },
}));
export const ItemNumbers = styled(Stack)(({ theme }) => ({
  top: '-7px',
  left: '-8px',
  background: theme.palette.goldenrod[100],
  height: '42px',
  width: '42px',
  position: 'absolute',
  borderRadius: '50%',
  fontSize: '18px',
  justifyContent: 'center',
  alignItems: 'center',
  ['@media(max-width: 1000px)']: {
    height: '34px',
    width: '34px',
  },
}));
export const ItemStyle = styled(Stack)(({ theme }) => ({
  position: 'relative',
  maxWidth: '412px',
  width: '33%',
  background: theme.palette.white,
  borderRadius: '24px',
  gap: '10px',
  padding: '30px 30px 26px',
  ['@media(max-width: 1000px)']: {
    width: '100%',
  },
}));
export const Title = styled(Typography)(() => ({
  marginBottom: '32px',
  textAlign: 'center',
  ['@media(max-width: 1000px)']: {
    marginBottom: '9px',
  },
}));
