import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { Advantages, HowItWorks, StartPart } from './components';
import { Question } from './components/Question';

export const Affiliate = () => {
  return (
    <Stack
      sx={{
        backgroundImage: 'url(/images/affiliate-background-desktop.png);',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Header />
      <StartPart />
      <Advantages />
      <HowItWorks />
      <Question />
      <Footer />
    </Stack>
  );
};
