import React from 'react';
import { Stack } from '@mui/material';
import { TitleStyle, TypographyStyle, WrapperStack } from './style';
import { ContentWrapper } from '../ui/ContentWrapper/style';

export const FinancialDisclosureAndTerms = () => {
  return (
    <ContentWrapper>
      <WrapperStack>
        <Stack flex={1}>
          <TitleStyle variant='h4'>Important Disclosures. Please Read Carefully</TitleStyle>
          <TypographyStyle>
            Persons facing serious financial difficulties should consider other alternatives or
            should seek out professional financial advice. This website is not an offer to lend.
            Need-Cash-Now.co is not a lender or lending partner and does not make loan or credit
            decisions. Need-Cash-Now.co connects interested persons with a lender or lending partner
            from its network of approved lenders and lending partners. Need-Cash-Now.co does not
            control and is not responsible for the actions or inactions of any lender or lending
            partner, is not an agent, representative or broker of any lender or lending partner, and
            does not endorse any lender or lending partner. Need-Cash-Now.co receives compensation
            from its lenders and lending partners, often based on a ping-tree model similar to
            Google AdWords where the highest available bidder is connected to the consumer.
            Regardless, Need-Cash-Now.co’s service is always free to you. In some cases, you may be
            given the option of obtaining a loan from a tribal lender. Tribal lenders are subject to
            tribal and certain federal laws while being immune from state law including usury caps.
            If you are connected to a tribal lender, please understand that the tribal lender’s
            rates and fees may be higher than state-licensed lenders. Additionally, tribal lenders
            may require you to agree to resolve any disputes in a tribal jurisdiction. You are urged
            to read and understand the terms of any loan offered by any lender, whether tribal or
            state-licensed, and to reject any particular loan offer that you cannot afford to repay
            or that includes terms that are not acceptable to you. This service is not available in
            all states. If you request to connect with a lender or lending partner in a particular
            state where such loans are prohibited, or in a location where Need-Cash-Now.co does not
            have an available lender or lending partner, you will not be connected to a lender or
            lending partner. You are urged to read and understand the terms of any loan offered by
            any lender or lending partner, and to reject any particular loan offer that you cannot
            afford to repay or that includes terms that are not acceptable to you.
          </TypographyStyle>
        </Stack>
        <Stack flex={1}>
          <TitleStyle variant='h4'>Lender’s or Lending Partner’s Disclosure of Terms</TitleStyle>
          <TypographyStyle>
            The lenders and lending partners you are connected to will provide documents that
            contain all fees and rate information pertaining to the loan being offered, including
            any potential fees for late-payments and the rules under which you may be allowed (if
            permitted by applicable law) to refinance, renew or rollover your loan. Loan fees and
            interest rates are determined solely by the lender or lending partner based on the
            lender’s or lending partner’s internal policies, underwriting criteria and applicable
            law. Need-Cash-Now.co has no knowledge of or control over the loan terms offered by a
            lender and lending partner. You are urged to read and understand the terms of any loan
            offered by any lenders and lending partners and to reject any particular loan offer that
            you cannot afford to repay or that includes terms that are not acceptable to you.
          </TypographyStyle>
          <TitleStyle variant='h4'>Late Payments Hurt Your Credit Score</TitleStyle>
          <TypographyStyle>
            Please be aware that missing a payment or making a late payment can negatively impact
            your credit score. To protect yourself and your credit history, make sure you only
            accept loan terms that you can afford to repay. If you cannot make a payment on time,
            you should contact your lenders and lending partners immediately and discuss how to
            handle late payments.
          </TypographyStyle>
        </Stack>
      </WrapperStack>
    </ContentWrapper>
  );
};
