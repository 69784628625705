import { TextStyle, TextBold, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';
import { Link } from '@mui/material';

export const InfoFirst = () => {
  return (
    <WrapperStack>
      <TitleStyle variant='h2'>COOKIE POLICY</TitleStyle>
      <TitleStyle variant='h5'>Last update: 18/03/2024</TitleStyle>
      <TitleStyle variant='h5'> 1. SUBJECT OF THE AGREEMENT</TitleStyle>
      <TextStyle>
        <TextBold>1.1. «Finlead LTD»</TextBold> is a company duly registered under the laws of
        Cyprus, with principal office at Agias Faneromenis, 143-145 Patsias Court, Flat/Office 201,
        Larnaca, Cyprus, which is operated by creditify.ro <TextBold>__________</TextBold>{' '}
        <TextBold>(«Finlead LTD», «Us», «We», or «Our»)</TextBold> understand that Your privacy is
        important to You and are committed to being transparent about the technologies it uses.
      </TextStyle>
      <TextStyle>
        <TextBold>1.2. </TextBold>
        This Cookie Policy constitutes the official policy that governs the use of cookies on the
        Website creditify.ro <TextBold>__________.</TextBold>
      </TextStyle>
      <TextStyle>
        <TextBold>1.3. </TextBold>
        As part of Our efforts to enhance the User(-s) (<TextBold>«You», «Your»</TextBold>)
        experience of using Our Website, we utilize cookies.
      </TextStyle>
      <TextStyle>
        <TextBold>1.4. </TextBold>
        Please read carefully the Cookie Policy before using Our Website. Aligned with Our Privacy
        Policy, the Cookie Policy states such terms shall bind the legal framework for the Website
        and the User.
      </TextStyle>
      <TitleStyle variant='h5'> 2. DEFINITIONS</TitleStyle>
      <TextStyle>
        <TextBold>2.1. </TextBold>
        Definitions not specified herein, shall have the same meaning as in Our Privacy Policy and
        Terms of Use.
      </TextStyle>
      <TextStyle>
        <TextBold>2.1.1. «Cookies»</TextBold> – are small text files stored on a user’s device when
        they access a website. These files contain data that can be used to remember user
        preferences, login information, and other browsing activity. Cookies are commonly used to
        personalize Website content, improve Website performance, and track user behavior.
      </TextStyle>
      <TitleStyle variant='h5'> 3. WHAT ARE COOKIES USED FOR?</TitleStyle>
      <TextStyle>
        <TextBold>3.1. </TextBold>
        Cookies are useful because they enable a Website to identify a User’s device. A cookie
        assigns a unique numerical identifier to Your web browser, allowing Us to identify You as
        the same visitor who previously visited Our Website and link Your usage of the Website to
        other Personal Data.
      </TextStyle>
      <TextStyle>
        <TextBold>3.2. </TextBold>Cookies perform many different functions, such as
        <ul>
          <li>letting You navigate between pages efficiently;</li>
          <li>remembering Your preferences, and generally improving Your experience;</li>
          <li>
            helping to ensure that advertisements You see online are more relevant to You and Your
            interests;
          </li>
          <li>fulfilling transactions and ensure Our Website perform as intended;</li>
          <li>identifying errors on Our Website;</li>
          <li>enabling You to return to previous travel searches;</li>
          <li>
            helping with data protection and potentially detect and investigate malicious of
            fraudulent activity;
          </li>
          <li>
            helping Us understand traffic to Our Website, including time and date of the visit, time
            and date of the last visit, and other Personal Data.
          </li>
        </ul>
      </TextStyle>
      <TextStyle>
        <TextBold>3.3. Finlead LTD</TextBold> uses cookies to:
      </TextStyle>
      <TextStyle>
        <TextBold>3.3.1. Optimization.</TextBold> Cookies help Us enhance Our Services and improve
        Our Website.
      </TextStyle>
      <TextStyle>
        <TextBold>3.3.2. Performance and analytics.</TextBold> We use cookies to improve Our
        Service. We Use cookies to produce analytics on Your use of Our Services to measure traffic,
        develop Our Services, and make Our Services more user-friendly and relevant to You.
      </TextStyle>
      <TextStyle>
        <TextBold>3.3.3. Marketing.</TextBold> We use cookies to remember Your product and page
        choices, as well as the fact that You visited Our Website.
      </TextStyle>
      <TitleStyle variant='h5'> 4. COOKIES USED ON THE WEBSITE</TitleStyle>
      <TextStyle>
        <TextBold>4.1. </TextBold>To the extent that Personal Data processed in connection with
        cookies qualifies as Personal Data, the legal basis for that processing is Your consent
        under GDPR, with the exception of strictly necessary cookies, as defined herein, which are
        required for the Website’s functionality and can be processed without the User’s consent.
      </TextStyle>
      <TextStyle>
        <TextBold>4.2. </TextBold> By clicking the <TextBold>«Allow all»</TextBold> button on the
        cookie banner, You allow Finlead LTD clear, explicit, and complete permission to utilize
        analytical technology to tailor content and enhance Website performance for a better user
        experience.
      </TextStyle>
      <TextStyle>
        <TextBold>4.3. </TextBold>By clicking <TextBold>«Customize»</TextBold> on the cookie banner,
        You will be able to choose which cookies You allow Us to use by changing Your preferences.
      </TextStyle>
      <TextStyle>
        <TextBold>4.4. </TextBold>By clicking{' '}
        <TextBold>«Accept only strictly necessary cookies»</TextBold> on the cookie banner, You can
        prevent Us from placing non-essential cookies on Your device. Nonetheless, You cannot deny
        strictly necessary cookies, which are required for the Website’s correct functioning.
      </TextStyle>
      <TextStyle>
        <TextBold>4.5. Finlead LTD</TextBold> may use several different types of cookies, including
        session cookies which are temporary and allow Us to link Your actions during a browser
        session and are erased when You close the browser. Other cookies are persistent cookies that
        remain on Your device for the period specified in the cookie.
      </TextStyle>
      <TextStyle>
        <TextBold>4.6. Finlead LTD</TextBold> may use following type of cookies:
      </TextStyle>
      <TextStyle>
        <TextBold>4.6.1. Strictly necessary cookies.</TextBold> Strictly necessary cookies are
        required to enhance Your experience with Our Service. Strictly essential cookies that deal
        with preferences help keep Our Services safe, including preventing fraudulent or disruptive
        behavior. Certain essential functionality are unavailable without these cookies. Strictly
        required cookies are always present and will be placed without Your permission. These
        cookies cannot be disabled.
      </TextStyle>
      <TextStyle>
        <TextBold>4.6.2. Performance cookies.</TextBold> Performance cookies collect Personal Data
        on how You use Our Website. They identify, for example, Our Users’ nationality or region,
        the total number of Users of Our Services, operating system, visited websites, following
        links, duration, and multiple Website visits, previously visited websites, most regularly
        visited websites, and issues You encountered. The data collected is aggregated and
        anonymized. It prohibits personal identification. Its sole objective is to evaluate and
        improve the User experience on Our website. To the extent that Personal Data processed in
        conjunction with performance cookies qualifies as Personal Data, the legal basis for that
        processing is Your consent <TextBold>under GDPR, </TextBold>{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/119472#:~:text=Creditorul%20realizeaz%C4%83%20evaluarea,%C8%99i%20complet%C4%83rile%20ulterioare.'>
          EMERGENCY DECREE No.50 of June 9
        </Link>
        , 2010 and{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/32733#:~:text=c%C3%A2nd%20prelucrarea%20este%20necesar%C4%83%20%C3%AEn%20vederea%20realiz%C4%83rii%20unui%20interes%20legitim%20al%20operatorului%20sau%20al%20ter%C8%9Bului%20c%C4%83ruia%20%C3%AEi%20sunt%20dezv%C4%83luite%20datele%2C%20cu%20condi%C8%9Bia%20ca%2'>
          Law No.677/2001
        </Link>
        .
      </TextStyle>
      <TextStyle>
        <TextBold>4.6.3. Marketing cookies.</TextBold> We may employ marketing strategies to promote
        Our Services to You, which may be tailored to Your specific needs and/or interests. The data
        collected is aggregated and anonymized. To the extent that Personal Data processed in
        conjunction with marketing cookies qualifies as Personal Data
        <TextBold> under GDPR, </TextBold>{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/119472#:~:text=Creditorul%20realizeaz%C4%83%20evaluarea,%C8%99i%20complet%C4%83rile%20ulterioare.'>
          EMERGENCY DECREE No.50 of June 9
        </Link>
        , 2010 and{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/32733#:~:text=c%C3%A2nd%20prelucrarea%20este%20necesar%C4%83%20%C3%AEn%20vederea%20realiz%C4%83rii%20unui%20interes%20legitim%20al%20operatorului%20sau%20al%20ter%C8%9Bului%20c%C4%83ruia%20%C3%AEi%20sunt%20dezv%C4%83luite%20datele%2C%20cu%20condi%C8%9Bia%20ca%2'>
          Law No.677/2001
        </Link>
        <TextBold> the legal basis for processing is Your consent.</TextBold>
      </TextStyle>
      <TextStyle>
        <TextBold>4.6.4. Preferences cookies.</TextBold> Preference cookies allow Finlead LTD to
        remember choices You make and provide more enhanced personal features. To the extent that
        Personal Data processed in connection with preference cookies should qualify as Personal
        Data, the legal ground for that processing is Your
        <TextBold> consent according to GDPR </TextBold>
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/119472#:~:text=Creditorul%20realizeaz%C4%83%20evaluarea,%C8%99i%20complet%C4%83rile%20ulterioare.'>
          EMERGENCY DECREE No.50 of June 9
        </Link>
        , 2010 and{' '}
        <Link href='https://legislatie.just.ro/Public/DetaliiDocument/32733#:~:text=c%C3%A2nd%20prelucrarea%20este%20necesar%C4%83%20%C3%AEn%20vederea%20realiz%C4%83rii%20unui%20interes%20legitim%20al%20operatorului%20sau%20al%20ter%C8%9Bului%20c%C4%83ruia%20%C3%AEi%20sunt%20dezv%C4%83luite%20datele%2C%20cu%20condi%C8%9Bia%20ca%2'>
          Law No.677/2001
        </Link>
        .
      </TextStyle>
      <TitleStyle variant='h5'> 5. FIRST-PARTY AND THIRD-PARTY COOKIES</TitleStyle>
      <TextStyle>
        <TextBold>5.1. </TextBold>We use both first-party and third-party cookies on Our Website.
      </TextStyle>
      <TextStyle>
        <TextBold>5.2. </TextBold>First-party cookies are cookies issued from the{' '}
        <TextBold>_________</TextBold> domain that are generally used to identify language and
        location preferences or render basic Website functionality.
      </TextStyle>
      <TextStyle>
        <TextBold>5.3. </TextBold>Third-party cookies belong to and are managed by other parties,
        such as Creditors (the list of Creditors is set out in the Terms of Use and Privacy Policy)
        or service providers. Some cookies, web beacons, and other tracking and storage technologies
        that we use are from third-party companies (third-party cookies), such as{' '}
        <TextBold>Google Analytics, </TextBold>
        to provide Us with web analytics and intelligence about Our Website which may also be used
        to provide measurement services and target ads. These companies use programming code to
        collect Personal Data about Your interaction with Our Website, such as the pages You visit,
        the links You click on, and how long You are on Our Website.
      </TextStyle>
      <TextStyle>
        <TextBold>5.4. </TextBold>When We collaborate with third-party suppliers to apply cookies on
        Our behalf and they have access to Personal Data obtained through cookies or other
        applications, they are subject to proper contractual safeguards under the GDPR regulations,
        which have become known as the <TextBold> «Cookie law».</TextBold> We store Personal Data
        gathered through cookies necessary for tailored services when You have consented to the use
        of cookies for those reasons, and only for the duration required to aggregate Personal Data
        that We use for Website performance and audience monitoring.
      </TextStyle>
      <TitleStyle variant='h5'> 6. CONSENT</TitleStyle>
      <TextStyle>
        <TextBold>6.1. </TextBold>Users are informed that they may withdraw their consent to abide
        by this policy at any time and may delete the cookies stored on their device through the
        settings and configurations of their browser that are mentioned below.
      </TextStyle>
      <TitleStyle variant='h5'> 7. DISABLING/ENABLING COOKIES VIA YOUR BROWSER</TitleStyle>
      <TextStyle>
        <TextBold>7.1. </TextBold>There are several ways for You to manage cookies and other
        tracking technologies. Depending on the browser in which the visitor browses Our Website, it
        may allow, block or delete the cookies installed on their device by modifying the
        configuration parameters of the installed browser. To change Your web browser settings for
        cookies, You can follow the instructions in the help section of Your web browser or visit
        <Link href='http://www.allaboutcookies.org/es/'>http://www.allaboutcookies.org/es/</Link>.
        You can also find information on how to disable specific cookies using the links provided
        below.
      </TextStyle>
      <TextStyle>
        <TextBold>7.1.1 </TextBold> Google Chrome{' '}
        <Link href='https://support.google.com/chrome/answer/95647 '>Google cookie settings</Link>;
      </TextStyle>
      <TextStyle>
        <TextBold>7.1.2 </TextBold> Mozilla Firefox{' '}
        <Link href='https://support.mozilla.org/en-US/products/firefox/protect-your-privacy/cookies'>
          Mozilla cookie settings
        </Link>
        ;
      </TextStyle>
      <TextStyle>
        <TextBold>7.1.3 </TextBold> Opera{' '}
        <Link href='https://help.opera.com/en/latest/web-preferences/'> Opera cookie settings</Link>
        ;
      </TextStyle>
      <TextStyle>
        <TextBold>7.1.4 </TextBold> Safari{' '}
        <Link href='https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac'>
          Safari cookie settings
        </Link>
        .
      </TextStyle>
      <TextStyle>
        <TextBold>7.2. </TextBold>For any other web browser, please visit Your web browser’s
        official web pages.
      </TextStyle>
      <TitleStyle variant='h5'> 8. DATA CONSERVATION</TitleStyle>
      <TextStyle>
        <TextBold>8.1. </TextBold>Some of the cookies used on the web save Your Personal Data as
        long as the session is open.
      </TextStyle>
      <TextStyle>
        <TextBold>8.2. </TextBold>The data extracted by third-party cookies will be stored for{' '}
        <TextBold>24 months</TextBold>.
      </TextStyle>
      <TitleStyle variant='h5'> 9. LIST OF COOKIES WE USE</TitleStyle>
    </WrapperStack>
  );
};
