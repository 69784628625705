import { styled } from '@mui/material';
import { ContentWrapper } from '../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(() => ({
  marginTop: '87px',
  flexDirection: 'column',
  paddingBottom: '80px',
  alignItems: 'center',
  ['@media(max-width: 1000px)']: {
    marginTop: '30px',
  },
}));
