import { Stack, styled } from '@mui/material';

export const WrapperStyle = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '38px',
  ['@media(max-width: 700px)']: {
    justifyContent: 'center',
  },
}));
export const LinkContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  ['@media(max-width: 700px)']: {
    display: 'none',
  },
}));
