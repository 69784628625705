export const ROUTES = {
  home: '/',
  loan: '/form',
  faq: '/faq',
  about: '/about',
  contacts: '/contacts',
  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  cookiesPolicy: '/cookies-policy',
  offerings: '/offerwall',
  unsubscribe: '/unsubscribe',
  affiliate: '/affiliate',
};
