import { Header } from '../../components/ui/Header';
import { Footer } from '../../components/ui/Footer';
import { Stack } from '@mui/material';
import { Info } from './components';
import { ContentWrapperStyle } from './style';

export const Terms = () => {
  return (
    <Stack>
      <Header />
      <ContentWrapperStyle>
        <Info />
      </ContentWrapperStyle>
      <Footer />
    </Stack>
  );
};
