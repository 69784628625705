import { SvgIcon } from '@mui/material';

export const FlagVn = () => {
  return (
    <SvgIcon sx={{ width: '110px', height: '80px' }}>
      <svg
        width='110'
        height='79'
        viewBox='0 0 110 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='mask0_1080_2167' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='79'>
          <rect width='110' height='79' rx='12' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_1080_2167)'>
          <rect x='-1' width='112' height='80' rx='2' fill='white' />
          <mask
            id='mask1_1080_2167'
            maskUnits='userSpaceOnUse'
            x='-1'
            y='0'
            width='112'
            height='80'
          >
            <rect x='-1' width='112' height='80' rx='2' fill='white' />
          </mask>
          <g mask='url(#mask1_1080_2167)'>
            <rect x='-1' width='112' height='80' fill='#EA403F' />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M55 49.36L40.8932 59.4164L46.0981 42.8924L32.1746 32.5836L49.4983 32.4276L55 16L60.5017 32.4276L77.8254 32.5836L63.9019 42.8924L69.1069 59.4164L55 49.36Z'
              fill='#FFFE4E'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
