import { Box, Typography, useTheme } from '@mui/material';
import { WorkImg1, WorkImg2, WorkImg3 } from '../../../../assets/img';
import {
  GetMoneyButtonStyle,
  ImgContainer,
  ItemNumbers,
  ItemStyle,
  ListStack,
  Title,
  WrapperStyle,
} from './style';

import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';
import { ROUTES } from '../../../../constants';
import { useNavigate } from 'react-router-dom';

export const HowItWorks = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const itemsList = [
    {
      title: 'Fill up the simple application form ',
      text: 'Connect with a helpful lender. We are committed to connecting you with an approved lender. We have also made this process simple. You do not need to visit hundreds of websites and fill out numerous documents.',
      img: WorkImg1,
    },
    {
      title: 'Choose the best offer for you',
      text: 'Connect with a helpful lender. We are committed to connecting you with an approved lender. We have also made this process simple. You do not need to visit hundreds of websites and fill out numerous documents.',
      img: WorkImg2,
    },
    {
      title: 'Take your money in 15 min.',
      text: 'Connect with a helpful lender. We are committed to connecting you with an approved lender. We have also made this process simple. You do not need to visit hundreds of websites and fill out numerous documents.',
      img: WorkImg3,
    },
  ];
  return (
    <ContentWrapper>
      <WrapperStyle>
        <Title variant='h2'>How It Works</Title>
        <ListStack>
          {itemsList.map(({ img, text, title }, index) => (
            <ItemStyle key={index}>
              <ItemNumbers>{index + 1}</ItemNumbers>
              <ImgContainer>
                <Box
                  component='img'
                  alt='dollar img'
                  src={img}
                  sx={{ maxWidth: '100%', maxHeight: '141px' }}
                />
              </ImgContainer>
              <Typography variant='h3' mt='32px' textAlign='start'>
                {title}
              </Typography>
              <Typography mt='20px' color={theme.palette.black[60]}>
                {text}
              </Typography>
            </ItemStyle>
          ))}
        </ListStack>
        <GetMoneyButtonStyle variant='contained' onClick={() => navigate(ROUTES.loan)}>
          Get money now
        </GetMoneyButtonStyle>
      </WrapperStyle>
    </ContentWrapper>
  );
};
