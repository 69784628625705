import React from 'react';
import { CheckboxStyle } from './style';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorText } from '../ErrorText/style';
import { Box, Stack } from '@mui/material';

type CheckboxRHFType = {
  name: string;
};

export const CheckboxRHF = ({ name }: CheckboxRHFType) => {
  const { control } = useFormContext();

  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <Stack>
      <CheckboxStyle
        checked={value ?? false}
        onChange={handleCheckboxChange}
        onBlur={onBlur}
        inputRef={ref}
      />
      <Box sx={{ position: 'absolute', marginTop: '24px' }}>
        {error ? <ErrorText>{error.message}</ErrorText> : <Box height='21px' />}
      </Box>
    </Stack>
  );
};
