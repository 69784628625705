export function hexToRgba(hex: string, alpha: number) {
  if (!/^#([A-Fa-f0-9]{6})$/.test(hex)) {
    throw new Error('Invalid HEX color format');
  }

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  let alphaHex = Math.round(alpha * 255)
    .toString(16)
    .toUpperCase();
  if (alphaHex.length < 2) {
    alphaHex = '0' + alphaHex;
  }

  return (
    '#' +
    r.toString(16).toUpperCase() +
    g.toString(16).toUpperCase() +
    b.toString(16).toUpperCase() +
    alphaHex
  );
}
