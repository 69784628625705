import { SvgIcon } from '@mui/material';
import { SxProps } from '@mui/system';

interface IDollarsRainIcon {
  sx?: SxProps;
}
export const DollarsRainIcon = ({ sx }: IDollarsRainIcon) => {
  return (
    <SvgIcon sx={{ width: '100%', height: '100%', ...sx }}>
      <svg
        width='553'
        height='595'
        viewBox='0 0 553 595'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='32' y='29' width='480' height='480' fill='url(#pattern0_1168_1761)' />
        <path d='M21.8027 96L0 132H34.1972L56 96H21.8027Z' fill='#75DCC3' />
        <path d='M68.8027 60L47 96H81.1972L103 60H68.8027Z' fill='#75DCC3' />
        <path d='M148.827 524L106 595H173.173L216 524H148.827Z' fill='#75DCC3' />
        <path d='M27.2987 357L9 387H37.7013L56 357H27.2987Z' fill='#75DCC3' />
        <path d='M518.413 212L497 247H530.587L552 212H518.413Z' fill='#75DCC3' />
        <path d='M480.856 447L461 480H492.144L512 447H480.856Z' fill='#75DCC3' />
        <path d='M521.856 430L502 463H533.144L553 430H521.856Z' fill='#75DCC3' />
        <path d='M419.237 0L406 22H426.763L440 0H419.237Z' fill='#75DCC3' />
        <defs>
          <pattern
            id='pattern0_1168_1761'
            patternContentUnits='objectBoundingBox'
            width='1'
            height='1'
          >
            <use
              xlinkHref='#image0_1168_1761'
              transform='translate(-0.187335 -0.187335) scale(0.00305482)'
            />
          </pattern>
        </defs>
      </svg>
    </SvgIcon>
  );
};
