import { BoxStyle, LoadBlockStyle, WrapperStyle } from './style';

export function ScreenLoader() {
  return (
    <BoxStyle>
      <WrapperStyle>
        <LoadBlockStyle>
          <span></span>
          <span></span>
          <span></span>
        </LoadBlockStyle>
      </WrapperStyle>
    </BoxStyle>
  );
}

export default ScreenLoader;
