import { styled, Box, Stack } from '@mui/material';

interface IWrapperStyled {
  isError?: boolean;
}
interface IErrorWrapperStyled {
  isError?: boolean;
}
export const WrapperStyled = styled(Stack)<IWrapperStyled>(({ theme, isError }) => ({
  width: '100%',
  '& input': {
    height: '67px',
    width: '100%',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    paddingLeft: '24px',
    borderRadius: '16px',
    color: theme.palette.black[100],
    border: `1px solid ${isError ? theme.palette.red : theme.palette.black[20]}`,

    '&:hover': {
      border: `1px solid ${theme.palette.black[40]}`,
    },
    '&:focus': {
      border: `2px solid ${isError ? theme.palette.red : theme.palette.black[40]}`,
      outline: 'none',
    },
  },
}));

export const ErrorWrapperStyled = styled(Box)<IErrorWrapperStyled>(({ isError }) => ({
  opacity: isError ? '1' : '0',
  height: '22px',
}));
