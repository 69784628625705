import { Box, Stack, Typography, useTheme } from '@mui/material';
import { AdvantagesImg1, AdvantagesImg2, AdvantagesImg3 } from '../../../../assets/img';
import { ListStack, Title, WrapperStyle } from './style';

import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';

export const Advantages = () => {
  const theme = useTheme();
  const itemsList = [
    {
      title: 'Safe & Secure',
      text: 'Connect with a helpful lender. We are committed to connecting you with an approved lender. We have also made this process simple. You do not need to visit hundreds of websites and fill out numerous documents.',
      color: theme.palette.turquoise,
      img: AdvantagesImg1,
    },
    {
      title: 'Simple and Fast',
      text: 'A simple application form If you have questions regarding a loan inquiry, approval, funded loan, payments or any other question about a particular loan, please contact your lender or lending partner directly. If you received a loan, you should already have the lender’s or lending partner’s contact information.',
      color: theme.palette.lavender[60],
      img: AdvantagesImg2,
    },
    {
      title: 'Loans from $1000',
      text: 'Your Loan, Your Business We recognize a personal loan is a private matter. Reasons for needing money range from critical automobile maintenance, unexpected medical expenses, much-needed family vacation, basic home improvements, etc.',
      color: theme.palette.goldenrod[100],
      img: AdvantagesImg3,
    },
  ];
  return (
    <ContentWrapper>
      <WrapperStyle>
        <Stack>
          <Title variant='h2'>Our Main Advantages</Title>
          <ListStack>
            {itemsList.map(({ img, color, text, title }, index) => (
              <Stack key={index} flex={1} maxWidth='320px'>
                <Stack alignItems='center'>
                  <Box
                    component='img'
                    alt='dollar img'
                    src={img}
                    sx={{ height: '200px', width: '200px' }}
                  />
                </Stack>
                <Typography variant='h3' color={color} mt='16px'>
                  {title}
                </Typography>
                <Typography mt='16px' color={theme.palette.black[40]}>
                  {text}
                </Typography>
              </Stack>
            ))}
          </ListStack>
        </Stack>
      </WrapperStyle>
    </ContentWrapper>
  );
};
