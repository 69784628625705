import React from 'react';
import { Box, Button } from '@mui/material';
import { ContentWrapper } from '../ContentWrapper/style';
import { LogoIcon } from '../../../assets/icons';
import { LinkContainer, WrapperStyle } from './style';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';

interface IHeader {
  bgColor?: string;
}
export const Header = ({ bgColor = 'transparent' }: IHeader) => {
  const navigate = useNavigate();
  const navigationOptions = [
    { title: 'About', path: ROUTES.about },
    { title: 'FAQs', path: ROUTES.faq },
    { title: 'Affiliate program', path: ROUTES.affiliate },
    { title: 'Contacts', path: ROUTES.contacts },
  ];
  return (
    <ContentWrapper sx={{ backgroundColor: bgColor }}>
      <WrapperStyle>
        <Box onClick={() => navigate(ROUTES.home)} sx={{ cursor: 'pointer' }}>
          <LogoIcon />
        </Box>

        <LinkContainer>
          {navigationOptions.map(({ title, path }, index) => (
            <Button
              key={index}
              variant='text'
              sx={{ marginRight: '42px' }}
              onClick={() => navigate(path)}
            >
              {title}
            </Button>
          ))}
        </LinkContainer>
      </WrapperStyle>
    </ContentWrapper>
  );
};
