interface QueryParams {
  [key: string]: string;
}

export function manageQueryParamsInLocalStorage(): QueryParams | undefined {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const paramsObject: QueryParams = {};
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });
  const savedParams = localStorage.getItem('queryParams');
  let combinedParams: QueryParams = {};

  if (savedParams) {
    combinedParams = { ...JSON.parse(savedParams), ...paramsObject };
  } else {
    combinedParams = paramsObject;
  }
  localStorage.setItem('queryParams', JSON.stringify(combinedParams));

  if (savedParams) {
    return JSON.parse(savedParams);
  } else {
    return undefined;
  }
}
