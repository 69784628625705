import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { TableListHeader } from './components/TableListHeader';
import { TableListRow } from './components/TableListRow/TableListRow';
import { TableStyle } from './style';

export interface ITableOption {
  id: string;
  label: string;
  minWidth?: string;
}

export interface TableRowProps {
  personalData?: string;
  whenWeCollect?: string;
  howWeUse?: string;
  whyWeUse?: string;
  name?: string;
  purpose?: string;
  expiry?: string;
  type?: string;
  party?: string;
}
interface ICustomTable {
  headCells: ITableOption[];
  rows: TableRowProps[];
}

export default function TableList({ rows, headCells }: ICustomTable) {
  return (
    <TableContainer>
      <TableStyle>
        <TableListHeader headCells={headCells} />
        <TableBody>
          {rows.map((row, index) => (
            <TableListRow key={index} headCells={headCells} row={row} />
          ))}
        </TableBody>
      </TableStyle>
    </TableContainer>
  );
}
