import { Stack, styled, Typography } from '@mui/material';
import { ContentWrapper } from '../../../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(() => ({
  marginTop: '98px',
  flexDirection: 'row',
  ['@media(max-width: 1000px)']: {
    marginTop: '22px',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    padding: 0,
  },
  ['@media(max-width: 700px)']: {
    height: '100dvh',
    maxHeight: window.innerHeight - 100 + 'px',
  },
}));
export const TypographyStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[60],
  marginTop: '20px',
  marginBottom: '40px',
  fontSize: '22px',
  fontWeight: '500',
  ['@media(max-width: 1000px)']: {
    textAlign: 'center',
    fontSize: '16px',
    marginBottom: '4px',
  },
}));

export const LeftStackStyle = styled(Stack)(() => ({
  flex: 1,
  maxWidth: '469px',
  ['@media(max-width: 1000px)']: {
    padding: '0 16px',
    maxWidth: '100%',
  },
}));
export const RightStackStyle = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '100%',
  flex: 1,
  '& .MuiBox-root': {
    height: '480px',
    width: '480px',
    position: 'absolute',
    ['@media(max-width: 560px)']: {
      flexDirection: 'column',
    },
  },
  '& .MuiSvgIcon-root': {
    height: '100%',
    maxHeight: '600px',
  },
  ['@media(max-width: 1000px)']: {
    overflow: 'hidden',
    '& .MuiBox-root': {
      height: '320px',
      width: '320px',
      position: 'absolute',
    },
  },
  ['@media(max-width: 600px)']: {
    '& .MuiBox-root': {
      height: '180px',
      width: '180px',
      position: 'absolute',
    },
  },
}));
