import { SvgIcon } from '@mui/material';

export const FlagRo = () => {
  return (
    <SvgIcon sx={{ width: '110px', height: '80px' }}>
      <svg
        width='110'
        height='79'
        viewBox='0 0 110 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='mask0_4201_1864' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='79'>
          <rect width='110' height='79' rx='12' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_4201_1864)'>
          <rect x='-1' y='-1' width='112' height='81' rx='2' fill='white' />
          <mask
            id='mask1_4201_1864'
            maskUnits='userSpaceOnUse'
            x='-1'
            y='-1'
            width='112'
            height='81'
          >
            <rect x='-1' y='-1' width='112' height='81' rx='2' fill='white' />
          </mask>
          <g mask='url(#mask1_4201_1864)'>
            <path d='M73 0H110V79H73V0Z' fill='#DE2035' />
            <path fillRule='evenodd' clipRule='evenodd' d='M37 79H73V0H37V79Z' fill='#FFD935' />
            <path d='M0 0H37V79H0V0Z' fill='#0748AE' />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
