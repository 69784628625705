import { Stack, styled } from '@mui/material';

export const WrapperStack = styled(Stack)(() => ({
  maxWidth: '50%',
  minWidth: '50%',
  flex: 1,
  '& .MuiTypography-root': {
    textAlign: 'left',
  },
  ['@media(max-width: 1000px)']: {
    maxWidth: '100%',
  },
}));
