import { TextField, Box } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { SxProps } from '@mui/system';
import React from 'react';
import { ErrorText } from '../ErrorText/style';

interface IProps {
  name: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  sx?: SxProps;
  fullWidth?: boolean;
  placeholder?: string;
}

export const TextFieldRHF = ({
  name,
  type = 'text',
  sx,
  fullWidth = true,
  placeholder,
  ...props
}: IProps) => {
  const methods = useFormContext();
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control: methods.control,
  });

  return (
    <TextField
      {...props}
      {...inputProps}
      sx={sx}
      fullWidth={fullWidth}
      inputRef={ref}
      helperText={error ? <ErrorText>{error.message}</ErrorText> : <Box height='21px' />}
      type={type}
      error={!!error}
      placeholder={placeholder}
      variant='outlined'
    />
  );
};
