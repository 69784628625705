import axios from 'axios';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`,
});
const axiosClientUnsubscribe = axios.create({
  baseURL: `${process.env.REACT_APP_API_UNSUBSCRIBE_URL}`,
});

export { axiosClient, axiosClientUnsubscribe };
