import Typography from '@mui/material/Typography';
import { TableCellStyle, TableRowStyle } from '../../style';
import { ITableOption, TableRowProps } from '../../index';

interface ITableListRow {
  row: TableRowProps;
  headCells: Array<ITableOption>;
}

export const TableListRow = ({ row, headCells }: ITableListRow) => {
  return (
    <>
      <TableRowStyle>
        {headCells.map((column) => {
          const value = (row as Record<string, string>)[column.id];

          return (
            <TableCellStyle key={column.id} width={column.minWidth}>
              <Typography variant='body1'>{value}</Typography>
            </TableCellStyle>
          );
        })}
      </TableRowStyle>
    </>
  );
};
