import { SvgIcon } from '@mui/material';

export const FlagUa = () => {
  return (
    <SvgIcon sx={{ width: '110px', height: '80px' }}>
      <svg
        width='110'
        height='79'
        viewBox='0 0 110 79'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask id='mask0_1080_2125' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='79'>
          <rect width='110' height='79' rx='12' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_1080_2125)'>
          <rect width='110.6' height='79' rx='2' fill='white' />
          <mask id='mask1_1080_2125' maskUnits='userSpaceOnUse' x='0' y='0' width='111' height='79'>
            <rect width='110.6' height='79' rx='2' fill='white' />
          </mask>
          <g mask='url(#mask1_1080_2125)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0 42.1333H110.6V0H0V42.1333Z'
              fill='#156DD1'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M0 79H110.6V42.1334H0V79Z'
              fill='#FFD948'
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
