import { Typography } from '@mui/material';
import { TextStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';

export const InfoFirst = () => {
  return (
    <WrapperStack>
      <Typography variant='h2' textAlign='left'>
        Frequently Asked Questions
      </Typography>
      <TextStyle>
        Creditify.mx provides a marketplace service where your loan request is submitted to a
        network of reputable lenders. Our service is always free-there is never a cost to you for
        submitting an inquiry. Your lender will have its own rates and fees that will be provided to
        you before you accept the loan. Creditify.mx is not a lender and does not make credit
        decisions.Creditify.mx provides a marketplace service where your loan request is submitted
        to a network of reputable lenders.
      </TextStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders.
      </TextStyle>
      <TextStyle>
        Our service is always free-there is never a cost to you for submitting an inquiry. Your
        lender will have its own rates and fees that will be provided to you before you accept the
        loan. Creditify.mx is not a lender and does not make credit decisions.Creditify.mx provides
        a marketplace service where your loan request is submitted to a network of reputable
        lenders. Our service is always free-there is never a cost to you for submitting an inquiry.
        Your lender will have its own rates and fees that will be provided to you before you accept
        the loan. Creditify.mx is not a lender and does not make credit decisions.
      </TextStyle>
    </WrapperStack>
  );
};
