import { TextBold, TextStyle, TitleStyle } from '../../../../components/ui/InfoStyle/style';
import { WrapperStack } from './style';
import { Link } from '@mui/material';

export const InfoSecond = () => {
  return (
    <WrapperStack>
      <TitleStyle variant='h5'>10. MODIFICATIONS TO THE POLICY</TitleStyle>
      <TextStyle>
        <TextBold>10.1. </TextBold>This Cookie policy may be changed at any moment by a legal
        provision or to fulfill the interests of Finlead LTD, which shall publish each amendment via
        this Website. It is the User’s duty to examine this Cookie policy on a regular basis to stay
        up to date on any changes that have occurred.
      </TextStyle>
      <TextStyle>
        <TextBold>10.2. </TextBold>
        If You have any further questions and/or comments, please contact Us via email at{' '}
        <Link href='mailto:support@creditify.ro'>support@creditify.ro</Link>.
      </TextStyle>
    </WrapperStack>
  );
};
