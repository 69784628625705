import { styled, Typography } from '@mui/material';

export const ContentWrapperStyle = styled('div')(() => ({
  marginTop: '55px',
  flexDirection: 'column',
  '& *': {
    fontFamily: 'Inter, sans-serif',
  },
  paddingBottom: '80px',
  ['@media(max-width: 768px )']: {
    marginTop: '46px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const CardWrapStyle = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingRight: '15px',
  paddingLeft: '15px',
  maxWidth: '306px',
  fontFamily: 'Inter, sans-serif',
  // ['@media(max-width: 992px)']: {
  //   flex: '0 0 33.333333%',
  //   maxWidth: '33.333333%',
  // },
  // ['@media(max-width: 768px)']: {
  //   flex: '0 0 50%',
  //   maxWidth: '50%',
  // },

  // ['@media(max-width:1040px  )']: {
  //   flex: '0 0 25%',
  //   maxWidth: '25%',
  // },
}));

export const CardInnerWrapStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '30px 20px 20px',
  marginBottom: '40px',
  borderRadius: '24px',
  backgroundColor: theme.palette.background.default,
}));
interface ICardHighlightStyle {
  background?: string;
}
export const CardHighlightStyle = styled('div')<ICardHighlightStyle>(({ theme, background }) => ({
  padding: '0 20px',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  textAlign: 'center',
  color: theme.palette.white,
  borderRadius: '24px 24px 0 0',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '14px',
  background: background || theme.palette.pearlAqua,
}));

export const ImgWrapStyle = styled('div')(() => ({
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ImgLinkStyle = styled('a')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '27px',
}));

export const ImgStyle = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
  height: 'auto',
}));

export const OfferListStyle = styled('ul')(() => ({
  listStyleType: 'none',
  margin: '0',
  padding: '0',
}));

export const OfferListItemStyle = styled('li')(({ theme }) => ({
  position: 'relative',
  height: '66px',
  '&:before': {
    position: 'absolute',
    content: '""',
    left: '0',
    bottom: '12px',
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.black[20],
  },
  '&:last-child:before': {
    position: 'absolute',
    content: '""',
    display: 'none',
  },
}));

export const OfferListItemTitleStyle = styled('div')(({ theme }) => ({
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '14px',
  color: theme.palette.black[60],
}));

export const OfferListItemValStyle = styled(Typography)(({ theme }) => ({
  padding: '5px 0 10px',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.black[100],
}));

export const PureLinkStyle = styled('a')(({ theme }) => ({
  display: 'block',
  marginBottom: '10px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '18px',
  color: theme.palette.primary.main,
  transition: 'color 0.2s',
}));

export const PureLinkPinkStyle = styled('a')(({ theme }) => ({
  display: 'block',
  marginBottom: '10px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '18px',
  color: theme.palette.secondary.main,
  transition: 'color 0.2s',
}));

export const CardBtnLinkStyle = styled('a')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '27px',
  fontFamily: 'Inter, sans-serif',
  height: '70px',
  textDecoration: 'none',
  color: theme.palette.white,
  borderRadius: '500px',
  backgroundColor: theme.palette.lavender[60],
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.lavender[80],
  },
}));
