import React, { useCallback, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { AutocompleteStyle, PaperStyle } from './style';
import { Box, TextField } from '@mui/material';
import { ErrorText } from '../ErrorText/style';

interface IEmailAutoComplete {
  name: string;
  placeholder: string;
}

export const EmailAutoComplete = ({ name, placeholder }: IEmailAutoComplete) => {
  const [isTarget, setIsTarget] = useState(false);
  const methods = useFormContext();
  const { control, setValue, watch, getValues } = methods;
  const fielValue = getValues(name);
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleClose();
  }, [setValue]);
  const nameWatch = watch(name);
  const domainList = [
    'gmail.com',
    'yahoo.com',
    'icloud.com',
    'hotmail.com',
    'yahoo.it',
    'outlook.com',
    'yahoo.co.uk',
    'yahoo.es',
  ].map((domain) => nameWatch?.split('@')[0] + '@' + domain);
  const filteredDomainsWatch = watch('filteredDomains');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(name, newValue);
    setValue(
      'filteredDomains',
      domainList?.filter((domain) => domain?.includes(newValue)),
    );
  };

  const handleOpen = useCallback(() => {
    if (fielValue?.includes('@') && filteredDomainsWatch?.length) {
      setOpen(true);
    }
  }, [fielValue, filteredDomainsWatch?.length]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (fielValue?.includes('@') && filteredDomainsWatch?.length && !isTarget) {
      handleOpen();
    } else {
      handleClose();
    }

    setIsTarget(false);
  }, [fielValue, filteredDomainsWatch, handleOpen]);

  return (
    <>
      <AutocompleteStyle
        {...inputProps}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={getValues(name) || null}
        disablePortal
        onChange={(event, newValue) => {
          setValue(name, newValue);
          handleClose();
          setIsTarget(true);
        }}
        fullWidth
        options={filteredDomainsWatch || []}
        PaperComponent={(props: React.HTMLAttributes<HTMLDivElement>) => (
          <PaperStyle {...props} elevation={5}>
            {props?.children}
          </PaperStyle>
        )}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            inputRef={ref}
            placeholder={placeholder}
            type='text'
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      {error ? <ErrorText>{error.message}</ErrorText> : <Box height='21px' />}
    </>
  );
};
