import { Stack, styled } from '@mui/material';

interface ILoanFormWrapperProps {
  mobileHeight?: string;
}

export const LoanFormWrapper = styled(Stack)<ILoanFormWrapperProps>(({ theme, mobileHeight }) => ({
  background: theme.palette.white,
  borderRadius: '24px',
  padding: '25px 38px 40px',
  width: '542px',
  minHeight: '651px',
  marginTop: '35px',
  '& .MuiButton-root': {
    height: '55px',
    minWidth: '124px',
  },
  ['@media(max-width: 600px)']: {
    marginTop: '0',
    width: '100%',
    borderRadius: '0',
    padding: '28px 16px 26px',
    minHeight: mobileHeight,
    height: mobileHeight,
    // minHeight: '680px',
    // height: 'calc(100dvh - 64px)',
  },
}));
export const ButtonWrapper = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& .MuiButton-root': {
    minWidth: '124px',
  },
  // ['@media(max-width: 600px)']: {
  //   flexDirection: 'column-reverse',
  //   '& .MuiButton-root': {
  //     minWidth: '100%',
  //     '&:first-child': {
  //       marginTop: '20px',
  //     },
  //   },
  // },
}));
