import { styled } from '@mui/material';
import { ContentWrapper } from '../../components/ui/ContentWrapper/style';

export const ContentWrapperStyle = styled(ContentWrapper)(() => ({
  marginTop: '110px',
  paddingBottom: '160px',
  alignItems: 'center',
  ['@media(max-width: 1000px)']: {
    marginTop: '36px',
    paddingBottom: '60px',
  },
}));
