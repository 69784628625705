import { Typography } from '@mui/material';
import { CheckboxStyle, WrapperStyle } from './style';
import { ISelectOption } from '../../types';

interface IProps extends ISelectOption {
  onHandleSelect: (value: number | string) => void;
  isActive: boolean;
}
export const SelectOption = ({ isActive, title, onHandleSelect, value }: IProps) => {
  return (
    <WrapperStyle isActive={isActive} onClick={() => onHandleSelect(value)}>
      <CheckboxStyle isActive={isActive} />
      <Typography fontSize='18px' fontWeight='500'>
        {title}
      </Typography>
    </WrapperStyle>
  );
};
