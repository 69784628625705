import { Button, Stack, Typography } from '@mui/material';
import { OptionsList } from '../../../../../components';
import { BackButton } from '../../../../../components/BackButton/style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ButtonWrapper } from '../../LoanForm/style';
import { useLoanContext } from '../../../context/context';
import { useState } from 'react';
import useScrollToTopOnPageChange from '../../../../../hooks/useScrollToTopOnPageChange';

interface IProps {
  onHandleStep: (stepAction: number) => void;
}
export const StepTwo = ({ onHandleStep }: IProps) => {
  useScrollToTopOnPageChange();
  const { loanReason, updateLoanData } = useLoanContext();
  const stepTwoOptions = [
    { title: 'Consolidate multiple existing loan', value: 'consolidate_multiple_existing_loan' },
    { title: 'Pay off credit card debt', value: 'pay_off_credit_card_debt' },
    { title: 'Pay bills', value: 'pay_bills' },
    { title: 'Medical expenses', value: 'medical_expenses' },
    { title: 'Repair home', value: 'repair_home' },
    { title: 'Repair car', value: 'repair_car' },
    { title: 'Pay rent', value: 'pay_rent' },
    { title: 'Vacations or travel', value: 'vacations_or_travel' },
    { title: 'Weddings or celebrations', value: 'weddings_or_celebrations' },
    { title: 'Pay for education', value: 'pay_for_education' },
    { title: 'Unexpected purchases', value: 'unexpected_purchases' },
    { title: 'Business loan', value: 'business_loan' },
    { title: 'Other', value: 'other' },
  ];
  const defaultIndexActiveOption = stepTwoOptions.findIndex(
    (option) => option?.value === loanReason,
  );

  const [currentReason, setCurrentReason] = useState<string>(
    stepTwoOptions[defaultIndexActiveOption >= 0 ? defaultIndexActiveOption : 1].value,
  );

  const onHandleChange = (value: string | number) => {
    setCurrentReason(String(value));
  };
  const onHandleNext = () => {
    onHandleStep(1);
    updateLoanData({ loanReason: currentReason });
  };

  return (
    <>
      <Stack flex={1} justifyContent='center'>
        <Typography variant='h3' sx={{ margin: '0 -16px 22px' }} textAlign='center'>
          What you need money for?
        </Typography>
        <OptionsList
          options={stepTwoOptions}
          defaultIndexActiveOption={defaultIndexActiveOption > 0 ? defaultIndexActiveOption : 1}
          onHandleChange={onHandleChange}
        />
      </Stack>
      <ButtonWrapper>
        <BackButton
          variant='contained'
          startIcon={<ArrowBackIcon />}
          onClick={() => onHandleStep(-1)}
        >
          Back
        </BackButton>

        <Button variant='contained' endIcon={<ArrowForwardIcon />} onClick={onHandleNext}>
          Next
        </Button>
      </ButtonWrapper>
    </>
  );
};
