import { Typography } from '@mui/material';
import { WrapperStack } from './style';
import { FormProvider, useForm } from 'react-hook-form';
import { useLoan, useUnsubscribe } from '../../../../hooks';
import { FormWrapper } from '../../../../components/ui/FormWrapper/form.style';
import { GetMoneyButton } from '../../../../components/GetMoneyButton/style';
import { PhoneNumberInput } from '../../../../components/ui/PhoneNumberInput/PhoneNumberInput';
import { toast } from 'react-toastify';
import { RequestHandler } from '../../../../components/RequestHandler/RequestHandler';
import useMediaQuery from '@mui/material/useMediaQuery';
import { unsubscribeSchema } from '../../../../validation/unsubscribe.schema';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormValues {
  phone: string;
}
export const Form = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const { currentData } = useLoan();
  const methods = useForm<FormValues>({
    resolver: yupResolver(unsubscribeSchema),
    defaultValues: {
      phone: currentData.phone,
    },
  });
  const { watch } = methods;
  const values = watch();
  const { mutate: unsubscribe, isLoading } = useUnsubscribe({
    onSuccess({ data }: { data: { result: string; row: number } }) {
      if (data?.result === 'success') {
        toast.success(
          'Ми отримали вашу заявку, рекламні комунікації за вашим номером будуть припинені через 24 години',
        );
      } else {
        toast.error('Error');
      }
    },
    onError(error) {
      toast.error((error as Error).message);
    },
  });

  const onSubmit = (formData: FormValues) => {
    const formDataToSend = new FormData();
    formDataToSend.append('phone', `${formData.phone}`);
    formDataToSend.append('sheetName', 'RO');
    unsubscribe(formDataToSend);
  };
  return (
    <RequestHandler isLoading={isLoading}>
      <FormProvider {...methods}>
        <WrapperStack>
          <Typography variant='h3' textAlign='center' mb='16px'>
            Відмова від новин і актуальної інформації
          </Typography>
          <Typography textAlign='center' mb={matches ? '30px' : '40px'}>
            Натиснувши кнопку «Відмовитися» ти безповоротно відмовишся від отримання новин на своій
            телефон
          </Typography>
          <FormWrapper style={{ width: '100%' }} onSubmit={methods.handleSubmit(onSubmit)}>
            <PhoneNumberInput
              sx={{ marginTop: '20px' }}
              name='phone'
              value={values.phone}
              mask='+40 (99) 999-9999'
              placeholder='+40 XX XXX XXXX'
            />
            <GetMoneyButton
              sx={{ marginTop: matches ? '40px' : '20px', width: '100%' }}
              variant='contained'
              type='submit'
            >
              Відмовитися
            </GetMoneyButton>
          </FormWrapper>
        </WrapperStack>
      </FormProvider>
    </RequestHandler>
  );
};
